<template>
    <div class="screen-height d-flex justify-center">
        <div class="all-width">
            <v-card class="screen-height shadow-off" loader-height="2" :loading="loading" :disabled="loading">
                <v-card-text>
                    <div class="d-flex align-center justify-space-between ">
                        <div class="">
                            <div class="d-flex">
                                <p class="mb-1">Home</p>
                                <p class="px-2 mb-1">/</p>
                                <p class="mb-1">{{ metadata.description }}</p>
                            </div>
                            <div class="d-flex align-center mb-1">
                                <p class="black--text font-weight-bold text-h5 ma-0">Tasklist</p>
                                <v-tooltip bottom content-class="tooltip-bottom" >
                                    <template v-slot:activator="{ on, attrs }"> 
                                        <v-btn 
                                            v-bind="attrs"
                                            v-on="on"
                                            class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                            size="24">mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                  </v-tooltip>
                            </div>
                        </div>
                        <div class="d-flex " v-if="metadata.managerlist.indexOf(userid) > -1">
                            <!-- <BulkChecklistUpload :project="id" @reload="refreshData()"
                                v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))" /> -->

                                <v-btn v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))" small color="" class="mr-1 border-dark px-4 py-1 " height="4vh" @click="bulkuploadtemplate=true; resetFlag = !resetFlag">
                                    <v-icon class="pr-2">mdi-file-document-plus-outline</v-icon>
                                    <span class="text-capitalize">Bulk Upload</span>
                                  </v-btn>

                            <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text ml-2"
                                @click="showlocationanduser=false;selectedLocation=null;addEditForm()"
                                v-if="metadata.managerlist.indexOf(userid) > -1 && metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"><v-icon
                                    class="mr-1">mdi-plus</v-icon>New Task</v-btn>
                        </div>
                    </div>
                    <div class="d-flex tab-header-height mb-7" v-if="!loading || metadata._id">
                        <div class="d-flex justify-space-around  gap-tab all-width ">
                            <div class="total_task_tab shadow-off total_task-border  d-flex justify-space-between cursor-pointer  width-tab  padding-tab"
                                @click="filterWithTask(null)"
                                :class="` ${(selectedTabStage === null) ? 'elevation-12' : ''}`">
                                <span class="">Total Tasks </span>
                                <span class="text-h6 font-weight-bold ">{{ summary.count || 0
                                    }}</span>
                            </div>
                            <div class=" pending_sub_tab shadow-off d-flex pending-task-border justify-space-between cursor-pointer width-tab  padding-tab"
                                @click="filterWithTask(0)" :class="` ${(selectedTabStage === 0) ? 'elevation-12' : ''}`">
                                <span class="">Pending
                                    Submissions</span>
                                <span class=" text-h6 font-weight-bold ">{{ summary.pending || 0
                                    }}</span>
                            </div>
                            <div class="pending_app_tab shadow-off approve-task-border d-flex justify-space-between cursor-pointer width-tab  padding-tab"
                                @click="filterWithTask(2)" :class="` ${(selectedTabStage === 2) ? 'elevation-12' : ''}`">
                                <span>Pending
                                    Approvals</span>
                                <span class=" text-h6 font-weight-bold ">{{ summary.pendingapproval ||
                                    0 }}</span>
                            </div>
                            <div class="closed_tab shadow-off d-flex  closed-task-border justify-space-between cursor-pointer width-tab  padding-tab"
                                @click="filterWithTask(3)" :class="` ${(selectedTabStage === 3) ? 'elevation-12' : ''}`">

                                <span class="">Closed Tasks</span>
                                <span class="text-h6 font-weight-bold ">{{ summary.approved || 0
                                    }}</span>


                            </div>
                        </div>
                    </div>
                    <div v-if="loading && !metadata._id">
                        <v-skeleton-loader
                            type="table-heading, list-item-two-line, article, article, article"></v-skeleton-loader>
                    </div>
                    <div class="" v-else>
                        <div class="d-flex mt-2 align-center justify-space-between">
                            <div class="d-flex ">
                                <div class="" style="height:5.6vh">
                                    <v-tabs hide-slider  class="pa-1 radius-small" style="height:5.2vh">
                                        <v-tab v-if="metadata.managerlist.indexOf(userid) > -1"
                                            @click="filterListByUser('manager')"
                                            :class="{ 'selected-item': userType === 'manager' }"
                                            class="radius-small px-2" style="height:4.5vh">
                                            <v-icon color="white" class="pr-2"
                                                v-if="userType === 'manager'">mdi-check</v-icon>
                                            Manager
                                        </v-tab>
                                        <v-tab @click="filterListByUser('preparer')"
                                            :class="{ 'selected-item': userType === 'preparer' }"
                                            class="radius-small px-2" style="height:4.5vh"><v-icon color="white"
                                                class="pr-2" v-if="userType === 'preparer'">mdi-check</v-icon>
                                            Preparer</v-tab>
                                        <v-tab @click="filterListByUser('approver')"
                                            :class="{ 'selected-item': userType === 'approver' }"
                                            class="radius-small px-2" style="height:4.5vh"> <v-icon color="white"
                                                class="pr-2" v-if="userType === 'approver'">mdi-check</v-icon>
                                            Approver</v-tab>
                                    </v-tabs>
                                    
                                </div>
                                <div class="mt-3" @click="overDueDateFun()" v-if="overDueDate">
                                <v-chip
                                color="currentcolor"
                                class="ml-0 mr-2 pending_app_tab"
                                label
                                small
                                >
                                <v-icon left class="custom-text">
                                    mdi-tune-variant
                                </v-icon>
                                 <span class="custom-text">Filter Overdue</span>
                                </v-chip>
                                </div>
                                <div  class="mt-3" @click="overDueDateFun()" v-else>
                                <v-chip
                                color="blue-grey lighten-3"
                                class="ml-0 mr-2 black--text"
                                label
                                small
                                >
                                <v-icon left class="custom-text">
                                    mdi-tune-variant
                                </v-icon>
                                <span class="custom-text"> Filter Overdue </span>
                                </v-chip>   
                                </div> 
                                
                                <v-checkbox  class="ml-5 my-3 hide shadow-off" :ripple="false" label="Filter Overdue"
                                    v-model="overDueDate" style="visibility: hidden;"></v-checkbox>
                            </div>
                            <div class="d-flex">

                                <!-- <v-tooltip bottom content-class="tooltip-bottom">
                                            <template  v-slot:activator="{ on, attrs }" >
                                                <v-btn icon v-bind="attrs" v-on="on"  @click="saveFilter(tableFilterHeader)">
                                                    <v-icon>mdi-image-auto-adjust</v-icon>
                                                </v-btn>
                                            </template>
                                          <span>Save filter</span>
                                        </v-tooltip>

                                        <v-tooltip bottom content-class="tooltip-bottom">
                                            <template  v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on"  @click="clearFilter()">
                                                    <v-icon>mdi-file-restore-outline</v-icon>
                                                </v-btn>
                                            </template>
                                          <span>Reset all filter</span>
                                        </v-tooltip> -->
                                
                                <!-- <v-btn text class="text-capitalize mb-2 " color="red" dark>
                                Save filter 
                                <v-icon
                                dark
                                right
                                >
                                mdi-filter-remove
                                </v-icon></v-btn>   -->
                                <v-slide-x-reverse-transition>
                                    <lb-string v-if="showSearch" v-model="searchQuery" placeholder="Search.."
                                        hidedetails label=""  :isClearable="false"/>
                                </v-slide-x-reverse-transition>
                                <v-tooltip  bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">  
                                    <v-btn v-bind="attrs" v-on="on" icon @click="toggleSearch">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>Search</span>
                                </v-tooltip>

                                 <v-tooltip bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon class="shadow-off" @click="getTaskDeatils(userType);"><v-icon>mdi-help-circle</v-icon></v-btn>
                                </template>
                                <span>All Tasks Status Report</span>
                                </v-tooltip>
                              

                                <v-menu offset-y transition="slide-y-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-tooltip bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                                <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  >
                                                    <v-icon>mdi-inbox-arrow-up</v-icon>
                                                </v-btn>
                                            </template>
                                          <span>Export</span>
                                        </v-tooltip>
                                      </template>


                                    <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh"
                                        class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                        <v-list-title class="ma-0  all-width">
                                            <div
                                                class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                                <p class="ma-0 font-weight-bold">Export</p>
                                                <v-icon color="primary">mdi-information-outline</v-icon>
                                            </div>
                                        </v-list-title>
                                        <v-divider></v-divider>
                                        <v-list-item style="max-height:25vh ; overflow:scroll"
                                            class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                            <v-list-item class="d-flex pa-0 px-2 ma-0 all-width "
                                                @click="downloadExcel()">
                                                <v-icon class="pr-2">mdi-download</v-icon>
                                                Download Excel
                                            </v-list-item>
                                            <!--Download PPT code commented out because octopus side changes are not yet completed                                            
                                            <v-list-item class="d-flex pa-0 px-2 ma-0 all-width "
                                                @click="downloadPPT()">
                                                <v-icon class="pr-2">mdi-download</v-icon>
                                                Download PPT
                                            </v-list-item> -->

                                        </v-list-item>

                                    </v-list>
                                </v-menu>

                                <lb-comment heading="Conversation"
                                    v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')" width="600"
                                    :recurring="false" :recurringText="''" :closed="metadata.stage !== 0"
                                    @markread="metadata.conversation_read = true" :notify="!metadata.conversation_read"
                                    :getapi="'/v2/financialclose/project/getconversation/' + metadata._id"
                                    :sendapi="'/v2/financialclose/project/addconversation/' + metadata._id"
                                    :readapi="'/v2/financialclose/project/markreadconversation/' + metadata._id" />


                                <v-menu ref="bulkmenu" :close-on-content-click="false" offset-y
                                    transition="slide-y-transition" v-if="selectItem.length>0">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-tooltip bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                                <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  >
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                          <span>Bulk Actions</span>
                                        </v-tooltip>
                                      </template>

                                    <v-list
                                        style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:25vh "
                                        class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                        <v-list-title
                                            class="ma-0 d-flex font-weight-bold pa-2 grayBackground font-weight-bold text-subtitle-1 all-width">
                                            Bulk Actions
                                            <v-spacer></v-spacer>
                                        </v-list-title>
                                        <v-list-item style="max-height:25vh ; overflow:scroll"
                                            class="d-flex flex-column all-width align-start pa-2 ma-0 " 
                                            v-if="userType === 'manager'"
                                            >
                                            
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" @click="bulkActionUserdilog('preparer')">
                                                <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                                                <span>Edit Preparer</span>
                                            </v-list-item>

                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" @click="bulkActionUserdilog('approver')">
                                                <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                                                <span>Edit Approver</span>
                                            </v-list-item>

                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" @click="bulkUpdatedueDate(selectItem)">
                                                <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                                                <span>Edit Due Date</span>
                                            </v-list-item>

                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" @click="bulkopenlinkBotDialog(selectItem)">
                                                <v-icon class="pr-2">mdi-link-plus</v-icon>
                                                <span>Link bot</span>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="bulkunlinkBot(selectItem)">
                                                <v-icon class="pr-2">mdi-link-off</v-icon>
                                                <span>Unlink bot</span>
                                            </v-list-item>
                                        </v-list-item>

                                        <v-list-item style="max-height:25vh ; overflow:scroll"
                                            class="d-flex flex-column all-width align-start pa-2 ma-0 " v-else-if="userType === 'preparer'">
                                            
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" @click=" bulkworkpaperuploadFun();">
                                                <v-icon class="pr-2">mdi-upload</v-icon>
                                                <span>Upload</span>
                                            </v-list-item>

                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                <!-- <v-icon class="pr-2"></v-icon>
                                                <span>comment</span> -->


                                                <lb-comment
                                                    :showname="true"
                                                    heading="Conversation"
                                                    v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')"
                                                    width="600"
                                                    :recurring="false"
                                                    :recurringText="''"
                                                    :closed="metadata.stage !== 0"
                                                    @markread="metadata.conversation_read = true"
                                                    :notify="!metadata.conversation_read"
                                                    :getapi="'/v2/financialclose/project/getconversation/'+metadata._id"
                                                    :sendapi="'/v2/financialclose/project/addconversation/'+metadata._id"
                                                    :readapi="'/v2/financialclose/project/markreadconversation/'+metadata._id"
                                                />

                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="bulkopenlinkBotDialog(selectItem)">
                                                <v-icon class="pr-2">mdi-link-plus</v-icon>
                                                <span>Link bot</span>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="bulkunlinkBot(selectItem)">
                                                <v-icon class="pr-2">mdi-link-off</v-icon>
                                                <span>Unlink bot</span>
                                            </v-list-item>
                                        </v-list-item>

                                        <v-list-item style="max-height:25vh ; overflow:scroll"
                                            class="d-flex flex-column all-width align-start pa-2 ma-0 " v-else-if="userType === 'approver'">
                                            
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="downloadbbulk(selectItem)">
                                                <v-icon class="pr-2">mdi-download</v-icon>
                                                <span>Download</span>
                                            </v-list-item>

                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  >
                                                <!-- <v-icon class="pr-2"></v-icon>
                                                <span>Comment</span> -->
                                                <lb-comment
                                                :showname="true"
                                                    heading="Conversation"
                                                    v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')"
                                                    width="600"
                                                    :recurring="false"
                                                    :recurringText="''"
                                                    :closed="metadata.stage !== 0"
                                                    @markread="metadata.conversation_read = true"
                                                    :notify="!metadata.conversation_read"
                                                    :getapi="'/v2/financialclose/project/getconversation/'+metadata._id"
                                                    :sendapi="'/v2/financialclose/project/addconversation/'+metadata._id"
                                                    :readapi="'/v2/financialclose/project/markreadconversation/'+metadata._id"
                                                />
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="changeChecklistStatus(selectItem, 'approve')">
                                                <v-icon class="pr-2">mdi-check-circle</v-icon>
                                                <span>Approve</span>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="changeChecklistStatus(selectItem, 'reject')">
                                                <v-icon class="pr-2">mdi-close-circle</v-icon>
                                                <span>Reject</span>
                                            </v-list-item>

                                            <v-divider></v-divider>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="bulkopenlinkBotDialog(selectItem)">
                                                <v-icon class="pr-2">mdi-link-plus</v-icon>
                                                <span>Link bot</span>
                                            </v-list-item>
                                            <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="bulkunlinkBot(selectItem)">
                                                <v-icon class="pr-2">mdi-link-off</v-icon>
                                                <span>Unlink bot</span>
                                            </v-list-item>
                                        </v-list-item>
                                        
                                    </v-list>


                                </v-menu>


                                <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y
                                    transition="slide-y-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-tooltip left content-class="tooltip-left">
                                          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                          <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                                          </template>
                                          <span>Customize column</span>
                                        </v-tooltip>
                                      </template>

                                    <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                                        class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                        <v-list-title class="ma-0  all-width">
                                            <div
                                                class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                                <p class="ma-0 font-weight-bold">Customize Column</p>
                                                <v-icon color="primary">mdi-information-outline</v-icon>
                                            </div>
                                            <v-divider></v-divider>
                                            <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2"
                                                v-model="searchcolumn" width="30vh" :clearable="false"
                                                appendiconinner="mdi-magnify" hidedetails radius="8px" />
                                        </v-list-title>
                                        <v-list-item style="max-height:25vh ; overflow:scroll"
                                            class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                            <template v-for="(item, index) in tableHeadersfilter">
                                                <v-list-item :key="index"  class="d-flex pa-0 px-2 ma-0 "  v-if="item.text !== 'Action'">
                                                <v-checkbox color="caccounting" 
                                                    :ripple="false" class="pa-0 ma-0" @change="addTableHeader(item)"
                                                    hide-details :input-value="item.visible"
                                                    :label="item.text"></v-checkbox>
                                            </v-list-item>
                                            </template>

                                        </v-list-item>
                                        <v-divider></v-divider>
                                <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                    <v-spacer></v-spacer>
                                    <v-btn class="black white--text" height="3vh" @click="saveHeader(tableHeadersfilter)"
                                        >Save</v-btn>
                                </v-list-item>

                                    </v-list>
                                </v-menu>


                            </div>
                        </div>
                    <lb-datatableaction v-if="custom_layout_success"
                            :headers="tableHeadersfilter"
                            :tabledata="tasklistfilterCopy"
                            :itemsperpage="itemsPerPage"
                            :searchterm="search"
                            @select-all-change="select"
                            :isDataSelected="selectItem.length > 0 && selectItem.length < paginatedFilteredItems.length"
                            @filter-item="filterPart" 
                            @refresh="getRefresh"
                            @sort-order="sortingOrder" 
                            :selectfilter="tableFilterHeader"
                            :page_details="page_details"
                            
                            @save-filter="saveFilter" 
                            @clear-item="clearPart" 
                            >
                           
                            <template v-slot:tbody>
                                <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer  "
                                    :class="{ 'low-opacity': item.status === false }">
                                    <td class="align-center justify-center">
                                        <v-checkbox dense
                                        color="caccounting"
                                        class="ma-0 py-1 "
                                        :ripple="false"
                                        hide-details
                                        :value="item"
                                        v-model="selectItem"
                                        multiple
                                       ></v-checkbox>
                                    </td>
                                    <template v-for="header in tableHeadersfilter">
                                        <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                                            class="px-2 "
                                            :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                                            <div class="" v-if="header.value === 'location'">
                                                <span>{{ getLocationById(item.location) }} </span>
                                            </div>
                                            <div class="two-line-clamp" @click="viewTaskDetails(item)"
                                                v-else-if="header.value === 'description'">
                                                <span>{{ getItemData(item, header.value) }}</span>
                                            </div>
                                            <div class="" v-else-if="header.value === 'duedays'">
                                                <div>
                                                    <v-chip
                                                    v-if="item[header.value] !== undefined && item[header.value] !== null && item[header.value] !== ''"
                                                    :label="true"
                                                    size="25"
                                                    :color="item[header.value] < 0 ? 'lightred' : 'purpleborder'"
                                                    :class="item[header.value] < 0 ? 'error--text' : 'caccounting--text'"
                                                    >
                                                    {{ item[header.value] }}
                                                    </v-chip>
                                                </div>
                                            </div>
                                            
                                            <div class="d-flex" v-else-if="header.value === 'stage'">
                                                <div class="pending_sub_tab radius-medium px-2 py-1"
                                                    v-if="item[header.value] === 0">
                                                    <v-icon>mdi-circle-small</v-icon>
                                                    <span>Pending Submission</span>
                                                </div>
                                                <div class="pending_app_tab radius-medium px-2 py-1"
                                                    v-if="item[header.value] === 2">
                                                    <v-icon>mdi-circle-small</v-icon>
                                                    <span>Pending Approval</span>
                                                </div>
                                                <div class="closed_tab radius-medium px-2 py-1"
                                                    v-if="item[header.value] === 3">
                                                    <v-icon>mdi-circle-small</v-icon>
                                                    <span>Closed </span>
                                                </div>

                                                
                                            </div>
                                            <div class="d-flex" v-else-if="header.value === 'upload'">
                                                <v-tooltip bottom content-class="tooltip-bottom" v-if="userType === 'preparer'">
                                                    <template v-slot:activator="{ on, attrs }" > 
                                                        <v-btn icon small v-bind="attrs"  v-on="on"  @click="openBulkUpload(item)">
                                                            <v-icon>mdi-upload</v-icon></v-btn>
                                                    </template>
                                                    <span>Upload</span>
                                                  </v-tooltip>
    
                                                  <v-tooltip bottom content-class="tooltip-bottom" v-else-if="userType === 'manager'">
                                                    <template v-slot:activator="{ on, attrs }" > 
                                                        <v-btn icon small v-bind="attrs"  v-on="on" @click="addEditForm(item)">
                                                            <v-icon>mdi-pencil-outline</v-icon></v-btn>
                                                    </template>
                                                    <span>edit</span>
                                                  </v-tooltip>
    
                                                  <v-menu offset-y transition="slide-y-transition" 
                                                  :close-on-content-click="false" v-else-if="userType === 'approver'">
                                                  <template v-slot:activator="{ on, attrs }" >
                                                      <v-tooltip bottom content-class="tooltip-bottom">
                                                        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                                        <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }" @click="OpenBulkUploadBox(item)"  ><v-icon >mdi-download</v-icon></v-btn>
                                                        </template>
                                                        <span>Download</span>
                                                      </v-tooltip>
                                                    </template>
      
      
                                                  <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh"
                                                      class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                                                      <v-list-title
                                                          class="all-width d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                                          Documnent Attatched
                                                      </v-list-title>
                                                      <v-divider></v-divider>
                                                      <v-list-item style="max-height:25vh ; overflow:scroll"
                                                          class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                                          <v-list-item class="d-flex pa-0 px-2 ma-0 all-width "  v-if="fileNamesList.length > 0">
                                                              <template v-for="(file, index) in fileNamesList">
                                                                  <div class="d-flex align-center justify-space-between all-width" :key="index">
                                                                      <span>{{ file.uploadname }}</span>
                                                                      <v-btn icon @click="$nova.downloadFile(file._id)"><v-icon
                                                                              class="pr-2">mdi-download</v-icon></v-btn>
      
                                                                  </div>
                                                              </template>
                                                          </v-list-item>
                                                          <v-list-item v-else-if="item.remark">
      
                                                            <span  class="blue--text text-decoration-underline">{{item.remark }}</span>
      
      
                                                          </v-list-item>
                                                          <v-list-item v-else class="gray--text d-flex align-center justify-center all-width">
                                                              No data
                                                          </v-list-item>
      
                                                      </v-list-item>
      
                                                  </v-list>
                                              </v-menu>
                                            </div>
                                            <div class="" v-else-if="header.value === '__tbtotals'">
                                        <td class="cursor-pointer hoverable text-right"
                                            v-if="(getItemData(item, header.value) || {}).total">
                                            {{ $nova.formatNumber(((getItemData(item, header.value) || {}).total ||
                                                0).toFixed(0)) }}</td>
                                        <td class="text-right" v-else>0</td>
                                            </div>
                                            <div class="" v-else-if="header.value === 'difference'">
                                                <td class="text-right ">{{ $nova.formatNumber((((item.__tbtotals ||
                                                    {}).total || 0) - (Number((item.result || {}).value?.total_value) || 0)).toFixed(0)) }}</td>
                                            </div>
                                            <div class="" v-else-if="header.value === 'scheduleAmount'">
                                                <td class="cursor-pointer hoverable text-right"
                                                    v-if="Number((item.result || {}).value?.total_value)">{{
                                                        $nova.formatNumber((Number((item.result ||
                                                            {}).value?.total_value) || 0).toFixed(0)) }}</td>
                                                <td class="text-right" v-else>0</td>
                                            </div>
                                            <div class="" v-else-if="header.value === 'preparerids'">
                                                
                                                <div class="d-flex px-1">
                                                    <div v-for="(preparer, index) in item?.preparerids.slice(0, 4)" :key="index"
                                                        class="avatars-group__item d-flex">
                                                        <v-tooltip top content-class="tooltip-top" v-if="preparer">
                                                            <template v-slot:activator="{ on }">
                                                                <v-avatar v-on="on" size="25" color="caccounting"
                                                                    class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                                    {{ preparer?.charAt(0).toUpperCase() }}
                                                                </v-avatar>
                                                            </template>
                                                            <span>{{ preparer }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <v-tooltip top content-class="tooltip-top" v-if="item?.preparerids?.length > 4">
                                                        <template v-slot:activator="{ on }">
                                                            <v-avatar v-on="on" size="25" color="lime lighten-1"
                                                                style="margin-left:-8px !important"
                                                                class="white--text font-small align-center justify-center d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                                <v-icon color="white">mdi-plus</v-icon>
                                                            </v-avatar>
                                                        </template>
                                                        <div class="" v-for="(preparer, index) in item?.preparerids" :key="index">
                                                            <span>{{ preparer }}</span>
                                                        </div>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div class="" v-else-if="header.value === 'approverids'">
                                                <div class="d-flex px-1">
                                                    <div v-for="(approver, index) in item?.approverids.slice(0, 4)" :key="index"
                                                        class="avatars-group__item d-flex">
                                                        <v-tooltip top content-class="tooltip-top" v-if="approver">
                                                            <template v-slot:activator="{ on }">
                                                                <v-avatar v-on="on" size="25" color="caccounting"
                                                                    class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                                    {{ approver?.charAt(0).toUpperCase() }}
                                                                </v-avatar>
                                                            </template>
                                                            <span>{{ approver }}</span>
                                                        </v-tooltip>
                                                    </div>

                                                    <v-tooltip top content-class="tooltip-top" v-if="item?.approverids?.length > 4">
                                                        <template v-slot:activator="{ on }">
                                                            <v-avatar v-on="on" size="25" color="green lighten-2"
                                                                style="margin-left:-8px !important"
                                                                class="white--text font-small align-center justify-center d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                                <v-icon color="white">mdi-plus</v-icon>
                                                            </v-avatar>
                                                        </template>
                                                        <div class="" v-for="(approver, index) in item?.approverids" :key="index">
                                                            <span>{{ approver }}</span>
                                                        </div>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div class="" v-else-if="header.value === 'created_at'">
                                                {{ $nova.formatDate(item.created_at) }}
                                            </div>
                                            <div class="" v-else-if="header.value === 'tags'">
                                                <template v-for="(item, index) in item.tags">
                                                    <v-chip label color="lightpurple" class="caccounting--text" :key="index">{{ item }}</v-chip>
                                                </template>
                                            </div>
                                            <div class="" v-else-if="header.value === 'statusdetail'">
                                              <div v-if="item.statusdetail ==='Pending Submission Due'">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"><v-icon color="#C69908">mdi-timer-sand-paused</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Submission Due</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else-if="item.statusdetail ==='Pending Submission Not Due'">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"> <v-icon color="#C69908">mdi-timer-sand-empty</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Submission Not Due</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else-if="item.statusdetail ==='Pending Submission Overdue'">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off">  <v-icon color="#C69908">mdi-timer-sand-full</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Submission Overdue</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else-if="item.statusdetail ==='Pending Approval Due'">
                                                 <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off">  <v-icon color="#4285F4">mdi-timer-sand-paused</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Approval Due</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else-if="item.statusdetail ==='Pending Approval Not Due'">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"> <v-icon color="#4285F4">mdi-timer-sand-empty</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Approval Not Due</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else-if="item.statusdetail ==='Pending Approval Overdue'">
                                                <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off"> <v-icon color="#4285F4">mdi-timer-sand-full</v-icon></v-btn>
                                                    </template>
                                                    <span>Pending Approval Overdue</span>
                                                </v-tooltip>
                                              </div>
                                              <div v-else>
                                                 <v-tooltip bottom content-class="tooltip-bottom">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon class="shadow-off">  <v-icon color="green">mdi-check-circle</v-icon></v-btn>
                                                    </template>
                                                    <span>Closed Task</span>
                                                </v-tooltip>
                                              </div>
                                            </div>
                                            <span v-else> {{ getItemData(item, header.value) }}</span>
                                        </td>
                                    </template>
                                    <td class="d-flex align-center px-3 single-line border-right border-left border-bottom">                                      

                                        <v-menu bottom offset-y :max-width="400" elevation-0 :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="text-center">
                                                    <v-btn icon small v-bind="attrs" v-on="on"> <v-icon>mdi-dots-horizontal</v-icon> </v-btn>
                                                </div>
                                            </template>
                                            <v-list style="width:25vh">
                                                <v-list-item class="d-flex pa-0 flex-column align-start justify-center px-1 " v-if="userType === 'manager'">


                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="addEditForm(item)">
                                                        <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                                                        <span>Edit</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <lb-comment heading="Conversation" width="600" :recurring="false"
                                                        :recurringText="''" 
                                                        :closed="metadata.stage !== 0" :showname="true"
                                                        @markread="item.conversation_read = true" :notify="!item.conversation_read"
                                                        :getapi="'/v2/financialclose/analysis/getconversation/' + metadata._id + '/c/' + item._id"
                                                        :sendapi="'/v2/financialclose/analysis/addconversation/' + metadata._id + '/c/' + item._id"
                                                        :readapi="'/v2/financialclose/analysis/markreadconversation/' + metadata._id + '/c/' + item._id"
                                                        :name="'Comment'"
                                                        :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="deleteChecklistItem(item)">
                                                        <v-icon class="pr-2">mdi-delete-outline</v-icon>
                                                        <span>Delete</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <lb-activitysidemenuNew :config="activitylogconfig"
                                                        :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${item._id}`"
                                                        :name="'Activity Log'" />
                                                    </v-list-item>
                                                    <!-- <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <v-icon class="pr-2">mdi-card-multiple-outline</v-icon>
                                                        <span>Update in Masters</span>
                                                    </v-list-item> -->


                                                </v-list-item>
                                                <v-list-item class="d-flex pa-0 flex-column align-start justify-center px-1"
                                                    v-else-if="userType === 'preparer'">
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" 
                                                        @click="openBulkUpload(item)">
                                                        <v-icon class="pr-2">mdi-file-upload-outline</v-icon>
                                                        <span>Upload</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" 
                                                        @click="workpaperTemplate()">
                                                        <v-icon class="pr-2">mdi-file-download-outline</v-icon>
                                                        <span>Download Template</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <lb-comment heading="Conversation" width="600" :recurring="false" :recurringText="''"
                                                            :showname="true" @markread="item.conversation_read = true" :notify="!item.conversation_read"
                                                            :getapi="'/v2/financialclose/analysis/getconversation/' + metadata._id + '/c/' + item._id"
                                                            :sendapi="'/v2/financialclose/analysis/addconversation/' + metadata._id + '/c/' + item._id"
                                                            :readapi="'/v2/financialclose/analysis/markreadconversation/' + metadata._id + '/c/' + item._id "
                                                            :name="'Comment'" :mentionapi="'/v2/financialclose/analysis/getusersformdata'" 
                                                            :closed="false"
                                                            />

                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <lb-activitysidemenuNew :config="activitylogconfig"
                                                        :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${item._id}`"
                                                        :name="'Activity Log'" />
                                                    </v-list-item>
                                                </v-list-item>
                                                <v-list-item class="d-flex pa-0 flex-column align-start justify-center px-1"
                                                    v-else-if="userType === 'approver'">


                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="$nova.downloadFiles(item.upload)">
                                                        <v-icon class="pr-2">mdi-tray-arrow-down</v-icon>
                                                        <span>Download All</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"   @click="changeChecklistStatus(item._id, 'approve')">
                                                        <v-icon class="pr-2">mdi-check-circle</v-icon>
                                                        <span>Approve</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="changeChecklistStatus(item._id, 'reject')">
                                                        <v-icon class="pr-2">mdi-close-circle</v-icon>
                                                        <span>Reject</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                    <lb-comment heading="Conversation" width="600" :recurring="false"
                                                        :recurringText="''" 
                                                        :closed="metadata.stage !== 0" :showname="true"
                                                        @markread="item.conversation_read = true" :notify="!item.conversation_read"
                                                        :getapi="'/v2/financialclose/analysis/getconversation/' + metadata._id + '/c/' + item._id"
                                                        :sendapi="'/v2/financialclose/analysis/addconversation/' + metadata._id + '/c/' + item._id"
                                                        :readapi="'/v2/financialclose/analysis/markreadconversation/' + metadata._id + '/c/' + item._id"
                                                        :name="'Comment'"
                                                        :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />

                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small" >
                                                        <lb-activitysidemenuNew :config="activitylogconfig"
                                                        :url="`/v2/financialclose/analysis/getlogs/${metadata._id}/c/${item._id}`"
                                                        :name="'Activity Log'" />
                                                    </v-list-item>


                                                </v-list-item>
                                                <v-divider class="pa-0 ma-0"></v-divider>
                                                <v-list-item class="d-flex pa-0 flex-column align-start justify-center px-1 ">

                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="openHelpdeskDialog(item)">
                                                        <v-icon class="pr-2">mdi-headphones</v-icon>
                                                        <span>Support</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="openlinkBotDialog(item)">
                                                        <v-icon class="pr-2">mdi-robot-outline</v-icon>
                                                        <span>Link Bot</span>
                                                    </v-list-item>
                                                    <v-list-item class="cursor-pointer hoverable-caccounting all-width  radius-small"  @click="viewTaskDetails(item)">
                                                        <v-icon class="pr-2">mdi-information-outline</v-icon>
                                                        <span>View Details</span>
                                                    </v-list-item>

                                                </v-list-item>
                                            </v-list>
                                        </v-menu>


                                    </td>
                                </tr>
                            </template>
                    </lb-datatableaction>

 
<div :class="[$store.state.sidedraweropen ? 'w-60 position-fixed' : 'all-width position-fixed']" v-if="pagecount > 1"
    style="bottom:0; overflow: hidden;background:white;z-index:11">
    <v-divider></v-divider>
    <v-row justify="center" class="d-flex pt-3 align-center justify-space-between">
        <v-col :cols="$store.state.sidedraweropen ? 4 : 2" class="pa-0 all-width">
            <v-container>
                <v-pagination v-model="page" class="font-small" :length="pagecount"></v-pagination>
            </v-container>
        </v-col>
        <v-col cols="5">
            <div class="d-flex align-center justify-start">
                <p class="ma-0 pa-0  px-2">Go to this page</p>
                <lb-number min="1" class="mr-1" label="" hidedetails :max="pagecount" @input="handlePageInput"
                    @change="updatePage" v-model="page" :style="`max-width:65px !important`" />
            </div>
        </v-col>
    </v-row>
</div>
</div>
</v-card-text>
</v-card>
<lb-drawer v-model="viewtaskdailog" heading='Task Details'>
    <template v-slot:body>
        <v-row>
            <v-col cols="2"><span
                    class="ma-0 text-body-1 caccounting-background caccounting--text pa-1 font-weight-bold ">{{
                    viewtaskdata.ref }}</span></v-col>
            <v-col cols="10" class="black--text font-weight-bold text-body-1">{{ viewtaskdata.description }}</v-col>
            <v-col cols="2">Role</v-col>
            <v-col cols="9">
                <v-chip label color="lightpurple" class="caccounting--text" v-if="viewtaskdata.preparer.includes(userid)">
                    Preparer
                </v-chip>
                <v-chip label color="lightpurple" class="caccounting--text" v-else-if="viewtaskdata.approver.includes(userid)">
                    Approver
                </v-chip>
                <v-chip label color="lightpurple" class="caccounting--text" v-else-if="viewtaskdata.preparer.includes(userid)">
                    Manager
                </v-chip>

            </v-col>
            <v-col cols="2">Status</v-col>
            <v-col cols="9">
                <div class=" width-fit">
                    <v-chip color="lightyellow" class="yellow--text" v-if="viewtaskdata.stage === 0">
                        <v-icon>mdi-circle-small</v-icon>
                        Pending Submission
                    </v-chip>
                    <v-chip color="lightblue" class="blue--text" v-if="viewtaskdata.stage === 2">
                        <v-icon>mdi-circle-small</v-icon>
                        <span>Pending Approval</span>
                    </v-chip>
                    <v-chip color="lightgreen" class="green--text" v-if="viewtaskdata.stage === 3">
                        <v-icon>mdi-circle-small</v-icon>
                        <span>Closed </span>
                    </v-chip>
                </div>

            </v-col>
            <v-col cols="2">Due</v-col>
            <v-col cols="9">

                <div v-if="new Date(viewtaskdata.duedate || '')">
                    <template v-if="viewtaskdata.stage === 3">
                        <v-chip color="lightred" class="error--text py-2"
                            v-if="(new Date(viewtaskdata.duedate || '') - new Date(metadata.to)) < -1"
                            small>
                            <v-icon class="mr-1" color="error">mdi-calendar-alert</v-icon>
                            {{ Math.ceil((new Date(metadata.to || '') - new Date(viewtaskdata.duedate)) /
                            (1000 * 24 * 60 * 60)) }} days ago
                        </v-chip>
                    </template>
                    <v-chip color="lightpurple" class="caccounting--text py-2"
                        v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) >= -1" small>
                        <v-icon class="mr-1" color="caccounting">mdi-calendar-blank</v-icon>
                        {{ Math.ceil((new Date(viewtaskdata.duedate || '') - new Date()) / (1000 * 24 * 60 * 60)) }}
                        days left
                    </v-chip>
                    <v-chip color="lightred" class="error--text py-2"
                        v-else-if="(new Date(viewtaskdata.duedate || '') - new Date()) < -1" small>
                        <v-icon class="mr-1" color="error">mdi-calendar-alert</v-icon>
                        {{ Math.ceil((new Date() - new Date(viewtaskdata.duedate || '')) / (1000 * 24 * 60 * 60)) }}
                        days ago
                    </v-chip>
                    <v-chip v-else label small icon color="white">
                        No date
                    </v-chip>
                </div>


                <!-- {{ viewtaskdata.duedate }} -->

            </v-col>
            <v-col cols="2">{{$store.state.LocationHeader || 'Location'}}</v-col>
            <v-col cols="9">
                <v-chip label color="lightpurple" class="caccounting--text">
                    {{ getLocationById(viewtaskdata.location) }}
                </v-chip>
            </v-col>
            <v-slide-y-transition>
                <v-row class="px-3 py-2" v-if="showMore">
                    <v-col cols="2">Priority</v-col>
                    <v-col cols="10">
                        <v-chip color="lightgray" label v-if="viewtaskdata.priority">{{ viewtaskdata.priority
                            }}</v-chip>
                    </v-col>
                    <v-col cols="2">Category</v-col>
                    <v-col cols="10">
                        <v-chip color="lightgray" label v-if="viewtaskdata.category">{{ viewtaskdata.category
                            }}</v-chip>
                    </v-col>
                    <v-col cols="2">Sub Category</v-col>
                    <v-col cols="10">
                        <v-chip color="lightgray" label v-if="viewtaskdata.subcategory">{{ viewtaskdata.subcategory
                            }}</v-chip>
                    </v-col>
                    <v-col cols="2">Assertions</v-col>
                    <v-col cols="10">
                        <template v-for="(item, index) in viewtaskdata.assertions">
                            <v-chip color="lightblue" label class="mr-2 blue--text" :key="index">{{ item }}</v-chip>
                        </template>
                    </v-col>
                    <v-col cols="2">Purpose</v-col>
                    <v-col cols="10">
                        <v-chip color="lightgray" label v-if="viewtaskdata.purpose">{{ viewtaskdata.purpose }}</v-chip>
                    </v-col>
                    <v-col cols="2">Instruction</v-col>
                    <v-col cols="10">
                        <v-chip color="lightgray" label v-if="viewtaskdata.instruction">{{ viewtaskdata.instruction
                            }}</v-chip>
                    </v-col>
                    <v-col cols="2">Tags</v-col>
                    <v-col cols="10">
                         <template v-if="viewtaskdata.tags">
                        <template v-for="(item, index) in viewtaskdata.tags">
                            <v-chip color="lightpurple" label class="mr-2 caccounting--text" :key="index">{{ item
                                }}</v-chip>
                        </template>
                         </template>
                    </v-col>
                </v-row>
            </v-slide-y-transition>
            <v-col cols="12">
                <v-btn text small @click="showMore = !showMore" color="white" class="px-0 ">
                    <v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                    <p class="ma-0 pa-0 text-button px-1 black--text ">
                        {{ showMore ? 'Hide items' : '7 more itmes' }}
                    </p>
                </v-btn>
            </v-col>
            <v-col cols="3" class="pa-1 ">
                <v-card
                    class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                    <v-card-title class="text-caption pb-3 pa-0"> Trial Balance </v-card-title>
                    <span class="text-subtitle-1 black--text">
                        {{ $nova.formatNumber(((viewtaskdata.__tbtotals || {}).total || 0).toFixed(0)) }}
                    </span>
                </v-card>
            </v-col>
            <v-col cols="3" class="pa-1 ">
                <v-card
                    class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                    <v-card-title class="text-caption all-width pb-3 pa-0 d-flex align-center justify-space-between">
                        Schedule Balance
                        <v-icon small size="6">mdi-information</v-icon>
                    </v-card-title>
                    <span class="text-subtitle-1 black--text">
                        {{ $nova.formatNumber((Number((viewtaskdata.result || {}).value?.total_value) || 0).toFixed(0))
                        }}
                    </span>
                </v-card>
            </v-col>
            <v-col cols="3" class="pa-1">
                <v-card class="d-flex radius-small shadow-off flex-column error-tab align-start justify-center pa-2">
                    <v-card-title class="text-caption all-width pb-3 pa-0 d-flex align-center justify-space-between">
                        Difference
                        <v-icon>mdi-information</v-icon></v-card-title>
                    <span class="text-subtitle-1 red--text">
                        {{ $nova.formatNumber((((viewtaskdata.__tbtotals || {}).total || 0) -
                        (Number((viewtaskdata.result ||
                        {}).value?.total_value) || 0)).toFixed(0)) }}
                    </span>
                </v-card>
            </v-col>
            <v-col cols="3" class="pa-1">
                <v-card
                    class="d-flex radius-small shadow-off total_task_tab tb_tab_border flex-column align-start justify-center pa-2">
                    <v-card-title class="text-caption pb-3 pa-0">Threshold</v-card-title>
                    <span class="text-subtitle-1 black--text">
                        {{ viewtaskdata.threshold ? viewtaskdata.threshold : 'NA' }}
                    </span>
                </v-card>
            </v-col>

            <v-col cols="6">
                <p class="ma-0 mb-1">Preparer</p>
                <div class="d-flex px-1">
                    <div v-for="(preparer, index) in viewtaskdata?.preparer" :key="index"
                        class="avatars-group__item d-flex">
                        <v-tooltip top content-class="tooltip-top">
                            <template v-slot:activator="{ on }" v-if="getNameById(preparer)">
                                <v-avatar v-on="on" size="25" color="caccounting"
                                    class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                    {{ getNameById(preparer)[0] }}
                                </v-avatar>
                            </template>
                            <span>{{ getNameById(preparer) }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
            <v-col cols="6">
                <p class="ma-0 mb-1">Approver</p>
                <div class="d-flex px-1">
                    <div v-for="(approver, index) in viewtaskdata?.approver" :key="index"
                        class="avatars-group__item d-flex">
                        <v-tooltip top content-class="tooltip-top" v-if="getNameById(approver)">
                            <template v-slot:activator="{ on }">
                                <v-avatar v-on="on" size="25" color="caccounting"
                                    class="white--text font-small  d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                    {{ getNameById(approver)[0] }}
                                </v-avatar>
                            </template>
                            <span>{{ getNameById(approver) }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card class="shadow-off pa-0">
                    <v-card-text class="pt-0 pb-0 d-flex align-center justify-center">
                        <v-tabs v-model="tabify" bg-color="primary"
                            class="border-bottom d-flex align-center justify-center" color="caccounting" height="34">
                            <v-tab :key="0" class="text-transform-none pa-0 px-2  justify-start">
                                Reconciliation
                                <v-icon class="mr-2">mdi-information-outline</v-icon>
                            </v-tab>
                            <v-tab :key="1" class="text-transform-none pa-0 px-2  justify-start">
                                File Attachments
                            </v-tab>
                            <v-tab :key="2" class="text-transform-none pa-0 px-2  justify-start">
                                Information Request
                            </v-tab>
                            <v-tab :key="3" class="text-transform-none pa-0 px-2  justify-start">
                                Linked GLs
                            </v-tab>
                            <v-tab :key="4" class="text-transform-none pa-0 px-2  justify-start"
                                @click="getConversation(viewtaskdata)">
                                Comments
                            </v-tab>
                        </v-tabs>
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-card-text class=" pa-1">
                        <v-tabs-items v-model="tabify">
                            <v-tab-item :key="0">
                                <v-simple-table dense class="mx-2 mb-2 simple-table border-on radius-small"
                                    v-if="(schedulelinkdialogitems || []).length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <th class="grayBackground pa-2 black--text text-left">Particulars</th>
                                            <th class="grayBackground pa-2 black--text text-right">Amount</th>
                                            <template v-for="(v, k) in schedulelinkdialogitems[0] || {}">
                                                <th v-if="k !== 'Amount' && k !== 'Particulars'" :key="k">{{ k }}</th>
                                            </template>
                                        </thead>
                                        <v-slide-y-transition class="" group tag="tbody">
                                            <tr v-for="(v, k) in schedulelinkdialogitems" :key="k" class="">
                                                <td class="">{{ v.Particulars }}</td>
                                                <td class="text-right">{{ $nova.formatNumber(Number(v.Amount ||
                                                    0).toFixed(0)) }}</td>
                                                <template v-for="(vv, kk) in schedulelinkdialogitems[0] || {}">
                                                    <td v-if="kk !== 'Amount' && kk !== 'Particulars'" :key="kk">
                                                        {{ v[kk] }}</td>
                                                </template>
                                            </tr>
                                        </v-slide-y-transition>
                                    </template>
                                </v-simple-table>
                                <p v-else>No data</p>

                            </v-tab-item>
                            <v-tab-item :key="1">
                                <div class="d-flex align-center justify-space-between">
                                    <span class="black--text text-body-1 ">Uploaded Document</span>
                                    <v-btn @click="$nova.downloadFiles(viewtaskdata.upload)" class="pa-0 cursor-pointer " text v-if="viewtaskdata.upload">
                                        <v-icon>mdi-download</v-icon>
                                        Download All
                                    </v-btn>
                                </div>
                                <v-container class="pa-0">
                                    <v-row no-gutters class="">
                                        <template v-if="(fileNamesList || [])?.length > 0">
                                            <v-col v-for="(v, k) in fileNamesList" :key="k" cols="4"
                                                class=" pa-3 ml-2 border-on radius-small">
                                                <v-sheet class="d-flex flex-column">
                                                    <p class="ma-0 black--text text-body-1 mb-3">{{ v.uploadname ||
                                                        v.uploadname || v.modifiedname }}</p>
                                                    <p class=" text-caption">{{ $nova.formatDateTime(v.created_at) }}</p>
                                                    <div class="mt-4">
                                                        <v-btn @click="$nova.downloadFile(v._id)"
                                                            class="cursor-pointer radius-mini  border-dark" text>
                                                            <v-icon>mdi-download</v-icon>
                                                            Download
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </template>
                                        <v-col v-if="viewtaskdata?.remark" cols='4'
                                            class=" pa-3 border-on radius-small ml-2">
                                            <p class="ma-0 black--text text-body-1 mb-3">Remark</p>
                                            <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}
                                            </p>
                                            <span 
                                                class="blue--text text-decoration-underline">{{viewtaskdata?.remark}}</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-tab-item>
                            <v-tab-item :key="2">
                                <div class="d-flex align-center justify-space-between mb-2 mt-1 px-2">
                                    <span class="font-weight-bold">Information Request</span>
                                    <div style="max-width:100px">
                                        {{inforeqstage? inforeqstage:0 }}% Completed
                                        <v-progress-linear
                                          :height="10" color="black"
                                          :value="inforeqstage"
                                        >
                                        </v-progress-linear>
                                      </div>
                                </div>
                                <v-simple-table class="mx-2 simple-table border-on radius-small mb-2"
                                    v-if="viewtaskdata.linkedInformationReq && viewtaskdata.linkedInformationReq.length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <th class="black--text pa-2 grayBackground text-left">Description</th>
                                            <th class="black--text pa-2 grayBackground text-left">Status</th>
                                            <th class="black--text pa-2 grayBackground text-left">Action</th>

                                        </thead>
                                        <v-slide-y-transition class="" group tag="tbody"
                                            style="overflow:hidden !important;">
                                            <tr v-for="(v, k) in viewtaskdata.linkedInformationReq" :key="k" class="">
                                                <td 
                                                class="d-flex align-center justify-space-between"
                                                @mouseover="hoverIndex = k"
                                                @mouseleave="hoverIndex = null"
                                              >
                                                {{ v.description }}
                                                <v-btn
                                                  v-if="hoverIndex === k"
                                                  x-small
                                                  class="elevation-1 shadow-on white caccounting--text text-caption text-capitalize"
                                                  @click="$nova.gotoLink({ path: `/financialclose/project/informationmanager/${metadata._id}` })"
                                                >
                                                  <v-icon></v-icon>
                                                  Open
                                                </v-btn>
                                              </td>
                                                <td>
                                                    <v-chip color="lightyellow" class="yellow--text"
                                                        v-if="v.stage === 0">
                                                        <v-icon>mdi-circle-small</v-icon>
                                                        Pending Submission
                                                    </v-chip>
                                                    <v-chip color="lightblue" class="blue--text" v-else-if="v.stage === 1">
                                                        <v-icon>mdi-circle-small</v-icon>
                                                        <span>Pending Approval</span>
                                                    </v-chip>
                                                    <v-chip color="lightgreen" class="green--text" v-else-if="v.stage === 2">
                                                        <v-icon>mdi-circle-small</v-icon>
                                                        <span>Closed </span>
                                                    </v-chip>
                                                    <v-chip color="lightred" class="error--text" v-else-if="v.stage === -1">
                                                        <v-icon>mdi-circle-small</v-icon>
                                                        <span>Cancelled</span>
                                                    </v-chip>
                                                </td>

                                                <td>
                                                    <v-menu :close-on-content-click="false" offset-y
                                                    transition="slide-y-transition">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text v-bind="attrs" class="text-capitalize"
                                                            v-on="on"><v-icon>mdi-download</v-icon> Download</v-btn>
                                                    </template>

                                                    <v-list class=" ma-0 d-flex  align-center justify-space-between all-width pa-2" v-if="v.fileid_detail">
                                                        {{v.fileid_detail.originalname || ""}}
                                                        <v-btn icon @click="$nova.downloadFile(v.fileid)"><v-icon>mdi-download</v-icon></v-btn>
                                                    </v-list>
                                                    <v-list class=" ma-0 d-flex  align-center justify-space-between all-width pa-2" v-if="v.response">
                                                        Response - {{v.response || ""}}
                                                    </v-list>
                                                    <v-list v-else class="gray--text ma-0 d-flex  align-center justify-center all-width pa-2">No data</v-list>
                                                </v-menu>
                                                </td>
                                            </tr>
                                        </v-slide-y-transition>
                                    </template>
                                </v-simple-table>
                                <div v-else class="grey--text px-4 mb-2">No Data</div>
                            </v-tab-item>
                            <v-tab-item :key="3">
                                <v-simple-table dense class="mx-2 simple-table border-on radius-small mb-2"
                                    v-if="(tblinkdialogitems || []).length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <th class="black--text pa-2 grayBackground text-left">Account No.</th>
                                            <th class="black--text pa-2 grayBackground text-left">Type</th>
                                            <th class="black--text pa-2 grayBackground text-left">Account Name</th>
                                            <th class="black--text pa-2 grayBackground text-right">Opening</th>
                                            <th class="black--text pa-2 grayBackground text-right">Transaction</th>
                                            <th class="black--text pa-2 grayBackground text-right">Journal Entries</th>
                                            <th class="black--text pa-2 grayBackground text-right">Closing</th>
                                        </thead>
                                        <v-slide-y-transition class="" group tag="tbody"
                                            style="overflow:hidden !important;">
                                            <tr v-for="(v, k) in tblinkdialogitems" :key="k" class="">
                                                <td class="">{{ v.glcode }}</td>
                                                <td class="">{{ v.type }}</td>
                                                <td class="">{{ v.name }}</td>
                                                <td class="text-right">
                                                    {{ $nova.formatNumber(v.opening_amount.toFixed(0)) }}</td>
                                                <td class="text-right">{{ $nova.formatNumber(v.transaction.toFixed(0))
                                                    }}
                                                </td>
                                                <td class="text-right">
                                                    {{ $nova.formatNumber(v.audit_entries.toFixed(0)) }}</td>
                                                <td class="text-right">
                                                    {{ $nova.formatNumber(v.closing_amount.toFixed(0)) }}</td>
                                            </tr>
                                        </v-slide-y-transition>
                                    </template>
                                </v-simple-table>
                                <div v-else class="grey--text px-4 mb-2">No Data</div>
                            </v-tab-item>
                            <v-tab-item :key="4">
                                <v-card class="border-on  radius-small">
                                    <div ref="chatContainer" class="pa-2" style="max-height:30vh; overflow:scroll">
                                        <div v-for="(item, index) in conversationList" :key="index">
                                            <!-- you -->

                                            <div class="d-flex align-center justify-end mt-3 mb-1"
                                                v-if="item.user == $store.state.user.id">
                                                <v-card class="shadow-off pa-0 ma-0" color="lightgray">
                                                    <v-card-text class="pa-1">
                                                        <v-btn icon small> <v-icon>mdi-emoticon-excited-outline</v-icon>
                                                        </v-btn>
                                                        <v-btn icon small> <v-icon>mdi-pencil-outline</v-icon> </v-btn>

                                                        <v-menu :close-on-content-click="false" offset-y
                                                            transition="slide-y-transition">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon v-bind="attrs"
                                                                    v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                                                            </template>

                                                            <v-list
                                                                style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh "
                                                                class=" ma-0 d-flex flex-column align-start justify-start all-width pa-2">
                                                                <v-btn text
                                                                    class="d-flex align-center justify-start all-width ma-0 pa-1">
                                                                    <v-icon class="pr-2">mdi-reply</v-icon> Reply
                                                                </v-btn>
                                                                <v-btn text
                                                                    class="d-flex align-center justify-start all-width ma-0 pa-1">
                                                                    <v-icon class="pr-2">mdi-pin</v-icon> Pin </v-btn>
                                                                <v-btn text
                                                                    class="d-flex align-center justify-start all-width ma-0 pa-1">
                                                                    <v-icon class="pr-2">mdi-delete</v-icon> Delete
                                                                </v-btn>
                                                                <v-btn text
                                                                    class="d-flex align-center justify-start all-width ma-0 pa-1">
                                                                    <v-icon class="pr-2">mdi-eye</v-icon> Message View
                                                                </v-btn>

                                                            </v-list>


                                                        </v-menu>
                                                    </v-card-text>
                                                </v-card>
                                                <v-tooltip bottom
                                                    v-if="(conversationList[index - 1] || {}).user !== item.user">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar class="ml-2" size="30" elevation="10" v-bind="attrs"
                                                            v-on="on" color="indigo">
                                                            <v-img v-if="item.user_detail.profile_url && showprofile"
                                                                alt="Avatar" width="30px" height="30" :size="30"
                                                                :src="item.user_detail.profile_url"></v-img>
                                                            <span class="white--text" v-else>{{ item.user_detail.name[0]
                                                                }}</span>
                                                        </v-avatar>
                                                    </template>
                                                    <span>{{ item.user_detail.name }}</span>
                                                </v-tooltip>

                                                <span v-else class="mr-9"></span>


                                            </div>
                                            <div class="mb-2 d-flex flex-column all-width justify-end"
                                                v-if="item.user == $store.state.user.id">
                                                <div class="timeFont mr-8 text-right mt-0">
                                                    {{
                                                    $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                                                    }}
                                                </div>
                                                <v-card class="mr-8 shadow-off radius-small" color="purpleborder">
                                                    <v-card-text class="shadow-off">
                                                        <div class="black--text" :id="'sent-chat-message-' + index">{{
                                                            item.value }}</div>
                                                    </v-card-text>
                                                </v-card>

                                            </div>
                                            <!-- sender -->
                                            <div class="mt-2" v-else>
                                                <div class="d-flex align-center justify-space-between"
                                                    v-if="(conversationList[index - 1] || {}).user !== item.user">
                                                    <div class="d-flex align-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-avatar dark class="" size="30" v-bind="attrs"
                                                                    v-on="on" :color="item.user_detail._id == $store.state.user.id
                                                                        ? 'indigo'
                                                                        : 'indigo'
                                                                        ">
                                                                    <v-img
                                                                        v-if="item.user_detail.profile_url && showprofile"
                                                                        alt="Avatar" width="30px" height="30" :size="30"
                                                                        :src="item.user_detail.profile_url"></v-img>
                                                                    <span class="white--text" v-else>{{
                                                                        item.user_detail.name[0] }}</span>
                                                                </v-avatar>
                                                            </template>
                                                            <span>{{ item.user_detail.name }}</span>
                                                        </v-tooltip>
                                                        <span class="ml-2">{{ item.user_detail.name }}</span>
                                                    </div>

                                                    <v-card class="shadow-off pa-0 ma-0" color="lightgray">
                                                        <v-card-text class="pa-1">
                                                            <v-btn icon small>
                                                                <v-icon>mdi-emoticon-excited-outline</v-icon>
                                                            </v-btn>

                                                            <v-btn icon small> <v-icon>mdi-reply</v-icon> </v-btn>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                                <div v-else class="ml-9"></div>

                                                <div class="mb-3 d-flex flex-column all-width justify-start">
                                                    <div class="ml-8">
                                                        {{
                                                        $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                                                        }}
                                                    </div>

                                                    <v-card class="ml-8 radius-small shadow-off" color="lightgray">
                                                        <v-card-text>
                                                            <div class="" :id="'receive-chat-message-' + index">{{
                                                                item.value }}
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="(conversationList || []).length === 0"
                                            class="d-flex flex-column align-center justify-center  all-height">
                                            <v-spacer></v-spacer>
                                            <p class="text-subtitle-1 grey--text">Be the first one to comment</p>
                                            <v-spacer></v-spacer>
                                        </div>
                                    </div>

                                    <div class="d-flex align-center all-width pa-2">
                                        <lb-string placeholder="Type your message here" label="" hidedetails
                                            @enter="sendQuery(viewtaskdata)" v-model="queryMsg" :isRequired="true" />
                                        <v-btn small color="caccounting" class="ml-3 py-5"
                                            @click="sendQuery(viewtaskdata)">
                                            <v-icon color="white">mdi-send</v-icon></v-btn>
                                    </div>

                                </v-card>

                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>


        </v-row>
    </template>
    <template v-slot:actions>
        <v-row class="my-1 px-2 d-flex align-center justify-center" v-if="userType === 'manager'">
            <p class="ma-0">*Required</p>
            <v-spacer></v-spacer>
            <v-btn class="mr-1 border-dark px-4 py-1 mr-2" @click="tabify=4">Comment</v-btn>
            <v-btn color="primary" class="white--text" @click="addEditForm(viewtaskdata)">Edit Task</v-btn>
        </v-row>
        <v-row class="my-1 px-2 d-flex align-center justify-center" v-if="userType === 'preparer'">
            <p class="ma-0">*Required</p>
            <v-spacer></v-spacer>
            <v-btn class="mr-1 border-dark px-4 py-1 mr-2" @click="$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate')">  Download Template</v-btn>
            <v-btn color="primary" class="white--text" @click="sendforreviewdrawer=true" :disabled="viewtaskdata.stage === 3 ? true: false ">Upload</v-btn>
        </v-row>
        <v-row class="my-1 px-2 d-flex align-center justify-center" v-if="userType === 'approver'">
            <!-- <v-btn class="mr-1 border-dark px-4 py-1 mr-2" text><v-icon>mdi-download</v-icon> Download</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn class="mr-1 border-dark px-4 py-1 mr-2" @click="changeChecklistStatus(viewtaskdata._id, 'reject')">Reject</v-btn>
            <!-- <v-btn class="mr-1 border-dark px-4 py-1 mr-2" @click="changeChecklistStatus(selectItem, 'reject')">Reject</v-btn> -->
            <v-btn color="primary" class="white--text" @click="changeChecklistStatus(viewtaskdata._id, 'approve')">Approve</v-btn>
            <!-- <v-btn color="primary" class="white--text" @click="changeChecklistStatus(selectItem, 'approve')">Approve</v-btn> -->
        </v-row>
    </template>
</lb-drawer>

<lb-drawer v-model="addeditdialog" :heading="addeditdata._id ? 'Edit Task' : 'Create Task'" width="600"
    :loading="addeditloading">
    <template v-slot:body>
        <div>
            <v-row>
                <v-col cols="12" class="my-1 py-1">
                    <p class="gray--text pa-0 ma-0  text-subtitle-1 mb-1">Task Description*</p>
                    <lb-textBox v-model="addeditdata.description" label="" requriedrows="3" :hidedetails="true"
                        :isRequired="true" :error="addeditdataerror.description" />
                </v-col>
                <v-col cols="6" class="my-1 py-1">
                    <p class="pa-0 ma-0  text-subtitle-1 gray--text">Category*</p>
                    <lb-tagsInput :multiple="false" :multiplechips="false" v-model="addeditdata.category"
                        appendiconinner="mdi-chevron-down" :hidedetails="true" :error="addeditdataerror.category"
                        :items="categoryAcc" />
                </v-col>

                <v-col cols="6" class="my-1 py-1">
                    <p class="pa-0 ma-0  gray--text text-subtitle-1">Sub Category*</p>
                    <lb-tagsInput :multiple="false" :multiplechips="false" v-model="addeditdata.subcategory"
                      :hidedetails="true" :error="addeditdataerror.subcategory" :items="subcategoryAcc" appendiconinner="mdi-chevron-down"/>
                </v-col>

                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text pa-0 ma-0  text-subtitle-1">Assertions</p>
                    <lb-tagsInput v-model="addeditdata.assertions" :hidedetails="true" :items="possibleassertions"
                        label="" :error="addeditdataerror.assertions" tagcolor="purpleborder" :multiple="true"
                        :multiplechips="true" />
                </v-col>
                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text ma-0 pa-0  text-subtitle-1">Due Date</p>
                    <lb-date v-model="addeditdata.duedate" label="date" :error="addeditdataerror.duedate"
                    :min="$nova.formatDateISO(new Date())" />
                </v-col>
                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text ma-0 pa-0  text-subtitle-1">Purpose</p>
                    <lb-textarea v-model="addeditdata.purpose" label="" height='100' :hidedetails="true"
                        :error="addeditdataerror.purpose" />
                </v-col>

                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text ma-0 pa-0  text-subtitle-1"> Instruction</p>
                    <lb-textarea v-model="addeditdata.instruction" label="" :error="addeditdataerror.instruction"
                        height="100" :hidedetails="true" />
                </v-col>


                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text ma-0 pa-0  text-subtitle-1">Priority</p>
                    <lb-dropdown v-model="addeditdata.priority" :items="priorityList" :error="addeditdataerror.priority"
                        label="" />
                </v-col>
                <v-col cols="6" class="my-1 py-1">
                    <p class="gray--text pa-0 ma-0  text-subtitle-1">Tags</p>
                    <lb-tagsInput tagcolor="purpleborder" :multiple="true" v-model="addeditdata.tags"
                        :multiplechips="true"  :items="Alltags" :error="addeditdataerror.tags" />
                </v-col>

                <v-col cols="4" v-if="showlocationanduser">
                    <p class="pa-0  ma-0 mb-2">Add {{$store.state.LocationHeader || 'Location'}} and users*</p>
                    <v-card class="shadow-off border-on">
                        <v-card-title class="grayBackground d-flex align-center justify-space-between px-2 py-1">
                            <span>{{ getLocationById(addeditdata.location)}}</span>
                            <v-btn icon color="error" @click="showlocationanduser = false; addeditdata.location=[]; addeditdata.preparer=[]; addeditdata.approver=[]"> <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pa-1">
                            <v-row class="pa-0">
                                <v-col class="py-2 d-flex flex-column" cols="4">Preparer
                                    <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" ref="preparerMenu">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span  v-bind="attrs" v-on="on"
                                                class="d-flex blue--text text-caption text-capitalize shadow-off"
                                                @click="addlocationanduserflag = 0">
                                                Edit
                                                
                                            </span>
                                        </template>
                   
                                        <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh"
                                            class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                                            >
                                            <v-list-title class="ma-0 all-width">
                                                <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 ">
                                                    <p class="ma-0 font-weight-bold">Choose Preparer</p>
                                                </div>
                                            </v-list-title>
                                            <v-divider></v-divider>
            
                                            <v-list-item style="max-height:25vh ; overflow:scroll"
                                                class="d-flex all-width align-start pa-1 ma-0 flex-column">
            
                                                <!-- Loop through usersList and manage checkbox manually -->
                                                <template v-for="(item, index) in usersList" >
                                                    <v-checkbox 
                                                        :key="index"
                                                        :label="item.name"
                                                        :value="item._id"
                                                        v-model="addeditdata.preparer"
                                                        class="pa-0 ma-0"
                                                    ></v-checkbox>
                                                </template>
                                            </v-list-item>
            
                                            <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                                <v-spacer></v-spacer>
                                                <v-btn class="black white--text" height="3vh" @click="$refs.preparerMenu.save()">Add</v-btn>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-2 d-flex avatars-group__item" cols="8">
                                    <div class=" d-flex avatars-group__item"
                                        v-for="(item,index) in addeditdata?.preparer?.slice(0,3)" :key="index">
                                        <v-tooltip top content-class="tooltip-top">
                                            <template v-slot:activator="{ on }">
                                                <v-avatar size="27" color="caccounting" class="white--text"
                                                    v-on="on">{{ getNameById(item)[0] }}</v-avatar>
                                            </template>
                                            <span>{{ getNameById(item) }}</span>
                                        </v-tooltip>
                                    </div>
                                    <v-tooltip top content-class="tooltip-top" v-if="addeditdata?.preparer?.length > 3">
                                        <template v-slot:activator="{ on }">
                                            <v-avatar v-on="on" size="25" color="caccounting"
                                                style="margin-left:-8px !important"
                                                class="white--text font-small align-center justify-center d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                <v-icon color="white">mdi-plus</v-icon>
                                            </v-avatar>
                                        </template>
                                        <div class="" v-for="(preparer, index) in addeditdata?.preparer?.slice(3)"
                                            :key="index">
                                            <span>{{ getNameById(preparer) }}</span>
                                        </div>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0">
                                <v-col class="py-2 d-flex flex-column" cols="4">
                                    Approver
                                    <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" ref="approverMenu">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span  v-bind="attrs" v-on="on"
                                                class="d-flex blue--text text-caption text-capitalize shadow-off"
                                                @click="addlocationanduserflag = 0">
                                                Edit
                                                
                                            </span>
                                        </template>
                   
                                    <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; width:20vh"
                                            class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                                           >
                                            <v-list-title class="ma-0 all-width">
                                                <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 ">
                                                    <p class="ma-0 font-weight-bold">Choose Approver</p>
                                                </div>
                                            </v-list-title>
                                            <v-divider></v-divider>
            
                                            <v-list-item style="max-height:25vh ; overflow:scroll"
                                                class="d-flex all-width align-start pa-1 ma-0 flex-column">
            
                                                <!-- Loop through usersList and manage checkbox manually -->
                                                <template v-for="(item, index) in usersList">
                                                        <v-checkbox 
                                                        :key="index"
                                                        :label="item.name"
                                                        :value="item._id"
                                                        v-model="addeditdata.approver"
                                                        class="pa-0 ma-0"
                                                    ></v-checkbox>
                                                </template>
                                            </v-list-item>
            
                                            <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                                <v-spacer></v-spacer>
                                                <v-btn class="black white--text" height="3vh" @click="$refs.approverMenu.save()">Add</v-btn>
                                            </v-list-item>
                                        </v-list>           
                                    </v-menu>
                                </v-col>

                                <v-col class="py-2 d-flex avatars-group__item" cols="8">
                                    <div class=" d-flex avatars-group__item"
                                        v-for="(item,index) in addeditdata?.approver?.slice(0,3)" :key="index">
                                        <v-tooltip top content-class="tooltip-top">
                                            <template v-slot:activator="{ on }">
                                                <v-avatar size="27" color="caccounting" class="white--text"
                                                    v-on="on">{{ getNameById(item)[0] }}</v-avatar>
                                            </template>
                                            <span>{{ getNameById(item) }}</span>
                                        </v-tooltip>
                                    </div>
                                    <v-tooltip top content-class="tooltip-top" v-if="addeditdata?.approver?.length > 3">
                                        <template v-slot:activator="{ on }">
                                            <v-avatar v-on="on" size="25" color="caccounting"
                                                style="margin-left:-8px !important"
                                                class="white--text font-small align-center justify-center d-flex pa-0 avatar-white-border ma-0 v-avatar">
                                                <v-icon color="white">mdi-plus</v-icon>
                                            </v-avatar>
                                        </template>
                                        <div class="" v-for="(approver, index) in addeditdata?.approver?.slice(3)"
                                            :key="index">
                                            <span>{{ getNameById(approver) }}</span>
                                        </div>
                                    </v-tooltip>
                                </v-col>

                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
                <v-col cols="12" v-else>
                    <p class="pa-0  ma-0 mb-2">Add {{$store.state.LocationHeader || 'Location' }} and users*</p>
                    <div class="border-on all-width d-flex align-center justify-center all-height " style="height:16vh">
                        <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" v-on="on"
                                    class="d-flex blue--text text-caption text-capitalize shadow-off"
                                    @click="addlocationanduserflag = 0">
                                    <v-icon>mdi-plus</v-icon>
                                    Add {{$store.state.LocationHeader || 'Location'}} and users
                                </v-btn>
                            </template>

                            <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important;"
                                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                                v-if="addlocationanduserflag===0">
                                <v-list-title class="ma-0  all-width">
                                    <div
                                        class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                        <p class="ma-0 font-weight-bold">Choose {{$store.state.LocationHeader || 'Location'}}</p>
                                        <v-icon color="primary">mdi-information-outline</v-icon>
                                    </div>
                                </v-list-title>
                                <v-divider></v-divider>
                                <v-list-item style="max-height:25vh ; overflow:scroll"
                                    class="d-flex all-width align-start pa-1 ma-0 flex-column">
                                    <template v-for="(item, index) in locationList">
                                        
                                        <span class="pa-1  radius-mini cursor-pointer all-width" :key="index"
                                            :class="`${selectedLocation === item._id ? 'border-on caccounting white--text' : 'hoverable-caccounting' }`"
                                            @click="addeditdata.location = item._id;selectedLocation=item._id">{{ item.name }}</span>
                                    </template>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                    <v-spacer></v-spacer>
                                    <v-btn class="black white--text" height="3vh" v-if="addeditdata.location"
                                        @click="addlocationanduserflag = 1">Next</v-btn>
                                </v-list-item>
                            </v-list>



                            <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important;"
                                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                                v-else-if="addlocationanduserflag === 1">
                                <v-list-title class="ma-0 all-width">
                                    <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 ">
                                        <v-btn icon @click="addlocationanduserflag = 0">
                                            <v-icon>mdi-keyboard-backspace</v-icon>
                                        </v-btn>
                                        <p class="ma-0 font-weight-bold">Choose Preparer</p>
                                        <v-icon color="primary">mdi-information-outline</v-icon>
                                    </div>
                                </v-list-title>
                                <v-divider></v-divider>

                                <v-list-item style="max-height:25vh ; overflow:scroll"
                                    class="d-flex all-width align-start pa-1 ma-0 flex-column">

                                    <!-- Loop through usersList and manage checkbox manually -->
                                    
                                    <template v-for="(item, index) in usersList">
                                        <v-checkbox :label="item.name" :key="index" class="pa-0 ma-0"
                                            :value="addeditdata.preparer?.includes(item._id)"
                                            @change="togglePreparer(item._id)"></v-checkbox>
                                    </template>
                                </v-list-item>

                                <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                    <v-spacer></v-spacer>
                                    <v-btn class="black white--text" height="3vh" v-if="addeditdata.preparer"
                                        @click="addlocationanduserflag = 2">Next</v-btn>
                                </v-list-item>
                            </v-list>






                            <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important; "
                                class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                                v-else-if="addlocationanduserflag === 2">
                                <v-list-title class="ma-0 all-width">
                                    <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 ">
                                        <v-btn icon @click="addlocationanduserflag = 1">
                                            <v-icon>mdi-keyboard-backspace</v-icon>
                                        </v-btn>
                                        <p class="ma-0 font-weight-bold">Choose Approver</p>
                                        <v-icon color="primary">mdi-information-outline</v-icon>
                                    </div>
                                </v-list-title>
                                <v-divider></v-divider>

                                <v-list-item style="max-height:25vh ; overflow:scroll"
                                    class="d-flex all-width align-start pa-1 ma-0 flex-column">

                                    <!-- Loop through usersList and manage checkbox manually -->
                                    <template v-for="(item, index) in usersList">
                                        <v-checkbox :label="item.name" :key="index" class="pa-0 ma-0"
                                            :value="addeditdata.approver?.includes(item._id)"
                                            @change="toggleApprover(item._id)"></v-checkbox>
                                    </template>
                                </v-list-item>

                                <v-list-item class="d-flex align-center justify-space-between all-width px-1">
                                    <v-spacer></v-spacer>
                                    <v-btn class="black white--text" height="3vh" v-if="addeditdata.approver"
                                        @click="addlocationanduserflag = 3; showlocationanduser=true">Submit</v-btn>
                                </v-list-item>
                            </v-list>




                        </v-menu>

                    </div>

                </v-col>
                <v-col cols=12 v-if="addeditdata?._tbtotals?.items || selectgls">
                    <span class="text-h6 font-weight-semibold"> Selected Account</span>
                    <br>
                    <div class="d-flex align-center justify-start">
                        <template v-for="(item, index) in (addeditdata?._tbtotals?.items[0] ||selectgls ||[]) " >
                            <v-chip label class="caccounting--text lightpurple mt-1 mx-1 d-flex " :key="index">
                                {{getGlname(item)?.glcode}}
                            </v-chip>
                        </template>
                    </div>
                </v-col>
                <v-col cols="12" style="height:50vh" v-if="addeditdata.location">
                    <v-menu :close-on-content-click="false" offset-y
                    transition="slide-y-transition"  ref="accountMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on" class="all-width align-center border-on justify-space-between"><span>Select Account Number</span><v-icon>mdi-chevron-down</v-icon></v-btn>
                    </template>

                    <v-list
                       
                        class=" ma-0 d-flex flex-column align-start justify-start all-width pa-2">
                        <div class=""  style="max-height:45vh ;min-width:100% ;overflow:scroll; z-index:13 !important; width:20vh ">
                        <lb-string label="Search" class="all-width" v-model="searchcustomfilter" hidedetails />
                        <v-simple-table dense class="all-width mt-2 border-on radius-small" >
                            <thead>
                              <tr>
                                <th class="gray-background">
                                  <v-checkbox
                                    class="ma-0 pa-0"
                                    hide-details
                                    multiple
                                    color="caccounting"
                                    v-model="selectAllgl"
                                    @change="toggleSelectAllGl"
                                  ></v-checkbox>
                                </th>
                                <th class="gray-background" scope="Name">Account No.</th>
                                <th class="gray-background" scope="columns">Account Name</th>
                                <th class="gray-background" scope="ref">Ref</th>
                                <th class="gray-background" scope="location">{{$store.state.LocationHeader || 'Location'}}</th>
                              </tr>
                            </thead>
                            <tbody>
                                <template v-for="(v, k) in filteredgllist">
                                    <tr :key="k">
                                      <td><v-checkbox class="mt-0" dense label="" v-model="selectgls" multiple :value="v._id"
                                          hide-details></v-checkbox></td>
                                      <td>{{ v.glcode }}</td>
                                      <td>{{ v.name }}</td>
                                      <td class="d-flex">
                                        <div v-for="(gg, jj) in v.reference_detail|| [] " :key="jj" class="d-flex">
                                          <v-chip label class="lightpurple caccounting--text mx-1" >{{ gg.ref }}</v-chip>
                                        </div>
                                      </td>
                                      <td>
                                        <div v-for="(vv, kk) in v.location_detail || [] " :key="kk">
                                          <span class="ml-2">{{ vv.name }}</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </template>
                                
                            </tbody>
                        </v-simple-table>
                        </div>
                        <div class="d-flex align-center all-width justify-end mt-2">
                            <v-btn small class="ml-2 white--text" color="black" @click="addSelectedGls(); $refs.accountMenu.isActive = false">Select</v-btn>
                        </div>

                    </v-list>


                </v-menu>
                </v-col>

            </v-row>
        </div>
    </template>
    <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addEditChecklistItem()">
            <span v-if="addeditdata._id">Update</span>
            <span v-else>Add</span>
        </v-btn>
    </template>
</lb-drawer>


<lb-drawer v-model="sendforreviewdrawer"  :heading="bulkworkpaper ? 'File Bulk Upload' : 'Single File Upload'" width="500" persistent>
    <template v-slot:body>
        <div class="d-flex align-center justify-space-between mb-4">
            <h5>Upload your document</h5>
            <v-btn class="black--text border-dark "
                @click="$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate')">
                <v-icon>mdi-download</v-icon> Download Template</v-btn>
        </div>
        <DragDropfile hidedetails :files="fileuplaoded" @update:files="FilesUpload"  :resetTrigger="resetFlag" />
        <p class="gray--text ma-0 mt-3">Remark (optional)</p>
        <lb-textBox requriedrows="3" :hidedetails="true" v-model="fileremark" label="Input" />
        <v-container class="pa-0 mt-3">
            <v-row no-gutters class="">
                <template v-if="(fileNamesList || [])?.length > 0">
                    <v-col v-for="(v, k) in fileNamesList" :key="k" cols="5" class="ml-2 pa-3 border-on radius-small">
                        <v-sheet class="d-flex flex-column">
                            <p class="ma-0 black--text text-body-1 mb-3">{{ v.uploadname || v.uploadname || modifiedname
                                }}</p>
                            <p class=" text-caption">{{ $nova.formatDateTime(v.created_at) }}</p>
                            <div class="mt-4">
                                <v-btn @click="$nova.downloadFile(v._id)"
                                    class="cursor-pointer radius-mini  border-dark" text>
                                    <v-icon>mdi-download</v-icon>
                                    Download
                                </v-btn>
                                <v-spacer></v-spacer>
                            </div>
                        </v-sheet>
                    </v-col>
                </template>
                <v-col v-if="viewtaskdata?.remark" cols='4' class="pa-3 ml-2 border-on radius-small">
                    <p class="ma-0 black--text text-body-1 mb-3">Remark</p>
                    <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p>
                    <span  class="blue--text text-decoration-underline">{{viewtaskdata?.remark}}</span>
                </v-col>
            </v-row>
        </v-container>
    </template>
    <template v-slot:actions>
        <v-checkbox label="Upload without document" hide-details class="ma-0" v-model="uploadiwithoutfile" />
        <v-spacer></v-spacer>
        <div v-if="bulkworkpaper">
            <v-btn small v-if="uploadiwithoutfile" class="ml-2 white--text" color="black" 
        @click="sendForReviewBulk()">Submit</v-btn>
        <v-btn v-else small class="ml-2 white--text" color="black" 
            @click="uploadFilesBulk()">Submit</v-btn>
        </div>
        <div v-else>
            <div>
        <v-btn small v-if="uploadiwithoutfile" class="ml-2 white--text" color="black" 
        @click="sendForReview()" :disabled="viewtaskdata.stage === 3 ? true: false ">Submit</v-btn>
        <v-btn v-else small class="ml-2 white--text" color="black" 
            @click="uploadFiles()" :disabled="viewtaskdata.stage === 3 ? true: false ">Submit</v-btn>
            </div>
        </div>
    </template>
</lb-drawer>


<lb-drawer v-model="bulklinkbotdialog" heading="Select a bot to bulk link" width="500" persistent >
    <template v-slot:body>

        <div class="">
            <p class="mb-1 gray--text">Select Bot*</p>
          <lb-dropdown v-model="selectedbot" label="" placeholder="Select requried bot type" :items="botlist"></lb-dropdown>
        </div>
      <div class="my-2 mb-4 font-weight-bold text-subtitle-1" v-if="selectedbot === '6470e55d999d5833d9d58224'" >Select Bot Result(s):</div>
       <div v-for="(task,i) in selectItem" :key="i">
         <div class="mb-3 d-flex align-center justify-start">
            <v-chip label color="lightpurple" class="caccounting--text mr-2">{{gettaskdetails(task).ref}}</v-chip>
            <span>{{gettaskdetails(task).description}}</span>
        </div> 
      <div v-if="selectedbot === '6470e55d999d5833d9d58224'" class="mb-3">
        <v-row class="ma-0 pa-0 my-2">
            <template v-for="lease in leaseScheduleItems || []">
                <v-col cols="3" class="ma-0 pa-0"  :key="lease" >
                    <v-checkbox color="caccounting" :label="lease" dense
                      class="d-inline-block ml-2" :value="lease"
                      v-model="leaseSchedulebulkValue[gettaskdetails(task)._id]" multiple hide-details
                    ></v-checkbox>
                </v-col>
            </template>
        </v-row>
        <v-divider></v-divider>
        </div>
         </div> 
     
    </template>
    <template v-slot:actions >
        <div class="d-flex align-center justify-space-between all-width mt-1">
        <span class="pl-2 d-flex align-center">Need additional bots? <v-btn text color="white" class="blue--text shadow-off text-lowercase text-caption px-1" height="2vh"  @click="$nova.gotoLink({path: '/financialclose/functionalsupport/'+metadata._id})" >Contact us</v-btn> </span>
          <v-btn small class="white--text radius-mini"  color="black" @click="bulklinkBot()">Submit</v-btn>
        </div>
    </template>
  </lb-drawer>


<lb-drawer v-model="bulkactionuserdialog" :heading="'Edit '+ bulkactionuserusertype" width="500" v-if="bulkactionuserusertype">
  <template v-slot:body>
    <div class="d-flex align-center">
        <v-chip color="lightpurple" class="caccounting--text d-flex align-center w-30">
             <v-icon class="mr-2">mdi-information-outline</v-icon>
             {{selectItem.length}}    
             task item selected
        </v-chip>
    </div>
    <p class="mb-1 mt-3 gray--text">Action</p>
    <lb-dropdown hidedetails v-if="!isLocation" label="" placeholder="Select action " v-model="selectaction" :items="bulkactionuserusertype ==='Approver' ? itemapprover: itempreparer"
    itemtext="name" itemvalue="value" class="mt-2" width="10" @change="actionselection(selectaction,bulkactionuserusertype)"/>

     <p class="mt-3 ma-0 gray--text pa-0">{{bulkactionuserusertype}}</p>
      <lb-dropdown hidedetails  label="" v-if="!isLocation" v-model="bulkactionuserid" :items="possibleusers"
        itemtext="displayname" itemvalue="_id" class="mt-1" />

        <lb-dropdown hidedetails label="" v-if="isLocation" v-model="bulkactionuserid" :items="locationList"
          itemtext="displayname" itemvalue="_id" class="mt-1" />

        <span class="gray--text" v-if="selectaction === 'replace'">Select {{bulkactionuserusertype}}</span>
        <lb-dropdown hidedetails label=""  v-model="changetouser" :items="usersList"
        itemtext="displayname" itemvalue="_id" class="mt-4" v-if="selectaction === 'replace'" :disabled="bulkactionuserid ? false: true" />

        <p class="gray--text mt-2" v-if="selectaction==='associate'">
            Selected user will be added to all selected tasks
        </p>

        <p class="gray--text mt-2" v-else-if="selectaction==='remove'">
        Selected user will be removed to all selected tasks
        </p>

        <p class="gray--text mt-2" v-else-if="selectaction==='replace'">
        Selected user will be replaced to all selected tasks
        </p>
        <p class="gray--text mt-2" v-else-if="selectaction==='move'">
        Selected user will be chnaged to preparer to all selected tasks
        </p>
    
  </template>
  <template v-slot:actions>
    <v-spacer></v-spacer>
     <v-btn color="error" small v-if="selectaction === 'remove'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,selectItem)">Remove</v-btn>
    <v-btn color="" class="black white--text" small v-else-if="selectaction === 'associate'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,selectItem)">Add</v-btn>
    <v-btn color="" class="black white--text" small v-else-if="selectaction === 'replace'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,selectItem,changetouser)">Replace</v-btn>
    <v-btn color="" class="black white--text" small v-else-if="selectaction === 'move'" @click="bulkManageUser(selectaction,bulkactionuserusertype,bulkactionuserid,selectItem)">Change</v-btn>
    <v-btn color="" class="black white--text" small v-else-if="selectaction === 'update'" @click="bulkManageUser(selectaction, bulkactionuserusertype, bulkactionuserid, selectItem)">Update</v-btn>
  </template>
</lb-drawer>

<lb-drawer v-model="bulkduedatedialog" heading="Edit Due Date" width="500" v-if="bulkduedatedialog">
  <template v-slot:body>
    <div class="d-flex align-center">
        <v-chip color="lightpurple" class="caccounting--text d-flex align-center w-30">
             <v-icon class="mr-2">mdi-information-outline</v-icon>
             {{selectItem.length}}    
             task item selected
        </v-chip>
    </div>
    <p class="mb-1 mt-3 gray--text">Due Date</p>
    <lb-date hidedetails  label="" placeholder="Select action " v-model="updatebulkduedate"
    class="mt-2" width="10" />
  </template>
  <template v-slot:actions>
    <v-spacer></v-spacer>
    <v-btn color="" class="black white--text" small @click="bulkduedateFun(selectItem,updatebulkduedate)">Update</v-btn>
  </template>
</lb-drawer>


<lb-drawer v-model="helpdeskdialog" heading="New Query" width="600" >
    <template v-slot:body>
      <div class="d-flex align-cenetr ">
        <v-chip label class="caccounting--text lightpurple mr-2">{{ helpdeskitem.ref }}</v-chip>
          
        
        <div class="font-weight-bold px-1 align-center d-flex">{{ helpdeskitem.description || "" }}</div>
      </div>
      
        <div class="my-3">
          <span class="text-subtitle-1 gray--text my-2">Discribe your request</span>
          <div class="text-field">
            <textarea class="input all-width border-on pa-2 radius-small" rows="4" cols="12" placeholder="Type the request here"
              v-model="helpdeskitem.__ticketdescripton"></textarea>
          </div>
        </div>

        <span class=" my-3 gray--text" >If you need Consark's assistance to complete this task or if you
            have any query
            relating to this tasklist item, you can submit this form to connect with our functional experts for quick
            resolution.</span>
      <div v-if="helpdeskitemnew">
        <v-btn v-if="(helpdeskitem.__existingtickets || []).length > 0" text small class="shadow-off mb-2"
          @click="helpdeskitemnew = false;"><v-icon class="mr-1">mdi-arrow-left</v-icon>Back to existing
          tickets</v-btn>

        <div class="mt-3">
          <span class="font-weight-bold">Discribe your request</span>
          <div class="text-field">
            <textarea class="input" rows="4" cols="12" placeholder="Type the request here"
              v-model="helpdeskitem.__ticketdescripton"></textarea>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn  v-if="metadata.stage === 0" @click="addHelpdeskQuery()" class="white--text black">Submit</v-btn>
    </template>
  </lb-drawer>


  <lb-drawer v-model="linkbotdialog" heading="Select a bot to link" width="500" persistent :loading="linkbotloading">
    <template v-slot:body>
      <div class="text-center">
        <div class="d-flex align-center justify-center">
          <lb-dropdown v-model="selectedbot" label="Select Bot*" :items="botlist"></lb-dropdown>
        </div>
      </div>


      <div v-if="selectedbot === '6470e55d999d5833d9d58224'">
        <div class="mt-2 mb-2"><strong>Select Bot Result(s):</strong></div>

        <v-row class="ma-0 pa-0 my-2">
            <template v-for="lease in leaseScheduleItems || []">
                <v-col cols="3" class="ma-0 pa-0"  :key="lease" >
                    <v-checkbox color="cacc" :label="lease" dense
                        class="d-inline-block ml-2" :value="lease"
                        v-model="leaseScheduleValue" multiple hide-details
                    ></v-checkbox>
                </v-col>
            </template>
        </v-row>
        </div>

    </template>
    <template v-slot:actions >
        <span class="pl-2 d-flex align-center">Need additional bots? <v-btn text color="white" class="blue--text shadow-off text-lowercase text-caption px-1" height="2vh"  @click="$nova.gotoLink({path: '/financialclose/functionalsupport/'+metadata._id})" >Contact us</v-btn> </span>

      <v-spacer></v-spacer>
      <v-btn small class="black white--text" @click="linkBot()">Link</v-btn>
    </template>
  </lb-drawer>



  <lb-drawer v-model="bulkworkpaperupload" heading="File Bulk Upload" width="500" persistent>
    <template v-slot:body>
        <div class="d-flex align-center justify-space-between mb-4">
            <h5>Upload your document</h5>
            <v-btn class="black--text border-dark "
                @click="$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate')">
                <v-icon>mdi-download</v-icon> Download Template</v-btn>
        </div>
        <DragDropfile hidedetails :files="fileuplaoded" @update:files="FilesUpload"  :resetTrigger="resetFlag" />
        <p class="gray--text ma-0 mt-3">Remark (optional)</p>
        <lb-textBox requriedrows="3" :hidedetails="true" v-model="fileremark" label="Input" />
        <v-container class="pa-0">
            <v-row no-gutters class="">
                <template v-if="(fileNamesList || [])?.length > 0">
                    <v-col v-for="(v, k) in fileNamesList" :key="k" cols="4" class=" pa-3 border-on radius-small">
                        <v-sheet class="d-flex flex-column">
                            <p class="ma-0 black--text text-body-1 mb-3">{{ v.uploadname || v.uploadname || modifiedname
                                }}</p>
                            <p class=" text-caption">{{ $nova.formatDate(v.date) }}</p>
                            <div class="mt-4">
                                <v-btn @click="$nova.downloadFile(v._id)"
                                    class="cursor-pointer radius-mini  border-dark" text>
                                    <v-icon>mdi-download</v-icon>
                                    Download
                                </v-btn>
                                <v-spacer></v-spacer>
                            </div>
                        </v-sheet>
                    </v-col>
                </template>
                <v-col v-if="viewtaskdata?.remark" cols='4' class=" pa-3 border-on radius-small">
                    <p class="ma-0 black--text text-body-1 mb-3">Remark</p>
                    <p class=" text-caption">{{ $nova.formatDate('2024-02-23T07:01:03.080Z') }}</p>
                   
                        <span>{{ viewtaskdata?.remark }}</span>
                </v-col>
            </v-row>
        </v-container>
    </template>
    <template v-slot:actions>
        <v-checkbox label="Upload without document" hide-details class="ma-0" v-model="uploadiwithoutfile" />
        <v-spacer></v-spacer>
        <v-btn small v-if="uploadiwithoutfile" class="ml-2 white--text" color="black" 
        @click="sendForReviewBulk()">Submit</v-btn>
        <v-btn v-else small class="ml-2 white--text" color="black" 
            @click="uploadFilesBulk()">Submit</v-btn>
    </template>
</lb-drawer>


<lb-drawer :Maxwidth="(uploaddata.length > 0) ? '1665' : '700'" v-model="bulkuploadtemplate" 
    heading="Bulk Upload" :loading="loading">
    <template v-slot:body>
      <div>
        <div class="d-flex align-center justify-space-between ">
          <span class="text-center mb-1" v-if="uploaddata.length === 0">Upload your document</span>
          <span v-else>Preview</span>

          <div class="d-flex">
            <v-btn text v-if="uploaddata.length>0" @click="clearfile()"> <v-icon>mdi-reload</v-icon> Re-Upload </v-btn>
            <v-btn  small class="border-dark d-flex align-center mb-1"
              @click="downloadTemplate()">Download Template</v-btn>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between " v-if="uploaddata.length > 0">
          <v-checkbox label="Show only items with error" color="caccounting" v-model="showdatawitherror"> </v-checkbox>
          <v-spacer></v-spacer>
        </div>
      </div>
        <div class="d-flex align-center justify-center">
          <DragDropfile v-if="uploaddata.length === 0" hidedetails :files="file" @update:files="updateFilesBulk"   :resetTrigger="resetFlag" />
      </div>   
      <div v-if="uploaddata.length > 0" class="radius-small border-on simple-table " style="max-height:73vh; overflow:scroll">
        <v-simple-table  >
          <template v-slot:default>
            <thead>
              <th class="text-center grayBackground" scope="sno">S No.</th>
              <th class="text-center grayBackground" scope="description">Description</th>
              <th class="text-center grayBackground" scope="category">Category</th>
              <th class="text-center grayBackground" scope="subcategory">Sub Category</th>
              <th class="text-center grayBackground" scope="location">Location</th>
              <th class="text-center grayBackground" scope="assertions">Assertions</th>
              <th class="text-center grayBackground" scope="purpose">Purpose</th>
              <th class="text-center grayBackground" scope="instructionHe">Instruction</th>
              <th class="text-center grayBackground" scope="preparer">Preparer</th>
              <th class="text-center grayBackground" scope="approver">Approver</th>
              <th class="text-center grayBackground" scope="tags">Tags</th>
              <th class="text-center grayBackground" scope="duedays">Due days</th>
              <th class="text-center grayBackground" scope="glcode">Account No.</th>
              <th class="text-center grayBackground" scope="priority">Priority</th>
            </thead>
            <v-slide-y-transition group tag="tbody" style="overflow:hidden !important;">
              <tr
              v-for="(row, index) in filteredData"
              :key="index"
              >
              <td>{{ index + 1 }}</td>
              <template v-for="(cell, key) in row">
                  <td v-if="errorsonly[index][key]" :key="key" class="text-caption pa-0  border-right-data" >
                    <div class="all-width all-height" style="border: 1px solid #F3B9BA">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-if="key === 'assertions' || key === 'location'"
                            class="d-inline-block"
                          >
                            <v-chip
                              v-for="(error, errorKey) in cell"
                              :key="errorKey"
                              label
                              v-bind="attrs"
                              v-on="on"
                              color="error"
                              small
                              outlined
                              class="pl-2 mb-1 mr-1"
                            >
                              <span class="pl-1">{{ error }}</span>
                            </v-chip>
                          </div>
                          <span v-else v-bind="attrs" v-on="on">
                            {{ errorsonly[index][key] }}
                          </span>
                        </template>
                      </v-tooltip>
                    </div>
                  </td>
                  <td v-else :key="key">
                    <div v-if=" key === 'tags' || key==='location'" class="d-flex align-center ">
                      <v-chip label  small outlined class="lightpurple caccounting--text pl-2 mb-1 mr-1">
                        <span class="pl-1">{{ cell[0] }}</span>
                      </v-chip>
                      <span v-if="cell.length>1" class="border-on px-1 ml-1">+{{cell.length-1}}</span>
                    </div>
                    <div v-else-if=" key==='approver' ||  key==='preparer'">
                      <v-avatar  v-for="(va, ka) in cell" :key="ka" size="24" outlined class="lightpurple caccounting--text  mb-1 mr-1">
                        <v-tooltip top content-class="tooltip-top" >
                            <template v-slot:activator="{ on, attrs }"> 
                                <span v-bind="attrs" v-on="on">   {{ va[0] }}    </span>
                            </template>
                            <span>{{va}}</span>
                          </v-tooltip>
                      </v-avatar>
                    </div>
                    <div v-else-if=" key==='assertions' " class="d-flex align-center ">
                      <span  v-if="cell?.length >=1">
                        {{ cell[0] }}
                      </span>
                      <span v-if="cell.length>1" class="border-on px-1 ml-1">+{{cell.length-1}}</span>
                    </div>
                    <div v-else-if=" key === 'glcode'">
                      <div v-for="(va, ka) in cell" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                          <span class="pl-1">{{ va}}</span>
                      </div>
                    </div>
                    <div v-else-if="key === 'priority'">
                        {{cell}}
                      <!-- <div v-for="(va, ka) in cell" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                          <span class="pl-1">{{ va}}</span>
                      </div> -->
                    </div>
                    <div v-else class="two-line-clamp">{{ cell }}</div>
                  </td>
                </template>
              </tr>
            </v-slide-y-transition>
          </template>
        </v-simple-table>
        
      </div>
      <p v-if="flag" class="error--text ma-0 pa-0 mt-4 text-caption">*These are fields where errors exist / information missing. Please rectify and re-upload to continue. (Preparer & Approver cannot be the same user)</p>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <div v-if="uploaddata.length > 0">
     <v-btn small class="ml-2 white--text" color="black" v-if="!flag" @click="uploadData()">Upload</v-btn>
        <v-btn class=""  @click="cleardata()">Cancel
        </v-btn>
      </div>
    </template>
  </lb-drawer>


  <lb-dialog v-model="taskdeTailsDialog" heading="All Task Staues Report"
        width="600">
        <template v-slot:body>
          <div>
                <div>The status below is shown for the  <span class="font-weight-bold">{{userType[0].toUpperCase() + userType.slice(1)}}</span> role: </div>
                <v-card-text class="pa-4">
                <div class="my-1">
                    <div class="d-flex ">
                        <v-icon color="#C69908">mdi-timer-sand-empty</v-icon>
                        <span class="ml-2 pending_sub_tab_color">Pending Submission Not Due</span>
                        <v-spacer></v-spacer>
                        <v-chip style="width: 40px; text-align: center;" color="#C69908" label small text-color="white">
                            {{taskdeTailsObje.pending_not_due || 0}}
                        </v-chip>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="my-1">
                    <div class="d-flex">
                        <v-icon color="#C69908">mdi-timer-sand-paused</v-icon>
                        <span class="ml-2 pending_sub_tab_color">Pending Submission Due</span>
                        <v-spacer></v-spacer>
                        <v-chip style="width: 40px; text-align: center;" color="#C69908" label small text-color="white">
                             {{taskdeTailsObje.pending_due || 0}}
                        </v-chip>
                    </div>
                </div>
                <v-divider></v-divider>
                 <div class="my-1">
                    <div class="d-flex">
                        <v-icon color="#C69908">mdi-timer-sand-full</v-icon>
                        <span class="ml-2 pending_sub_tab_color">Pending Submission Overdue</span>
                        <v-spacer></v-spacer>
                        <v-chip style="width: 40px; text-align: center;" color="#C69908" label small text-color="white">
                             {{taskdeTailsObje.pending_over_due || 0}}
                        </v-chip>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="mt-8">
                 <v-divider></v-divider>
                <div class="my-1">
                    <div class="d-flex">
                        <v-icon color="#4285F4">mdi-timer-sand-empty</v-icon>
                        <span class="ml-2 pending_app_tab_color">Pending Approval Not Due</span>
                        <v-spacer></v-spacer>
                        <v-chip style="width: 40px; text-align: center;" color="#4285F4" label small text-color="white">
                             {{taskdeTailsObje.pendingapproval_not_due || 0}}
                        </v-chip>
                    </div>
                </div>
                
                <v-divider></v-divider>
                <div class="my-1">
                    <div class="d-flex">
                        <v-icon color="#4285F4">mdi-timer-sand-paused</v-icon>
                        <span class="ml-2 pending_app_tab_color">Pending Approval Due</span>
                        <v-spacer></v-spacer>
                        <v-chip class=""  style="width: 40px; text-align: center;" color="#4285F4" label small text-color="white">
                             {{taskdeTailsObje.pendingapproval_due || 0}}
                        </v-chip>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="my-1">
                    <div class="d-flex">
                        <v-icon color="#4285F4">mdi-timer-sand-full</v-icon>
                        <span class="ml-2 pending_app_tab_color">Pending Approval Overdue</span>
                        <v-spacer></v-spacer>
                        <v-chip class=""  style="width: 40px; text-align: center;" color="#4285F4" label small text-color="white">
                             {{taskdeTailsObje.pendingapproval_over_due || 0}}
                        </v-chip>
                    </div>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="mt-8">
                     <v-divider></v-divider>
                <div class="my-1 ">
                    <div class="d-flex">
                        <v-icon color="green">mdi-check-circle</v-icon>
                        <span class="ml-2 closed_tab_color">Closed Task</span>
                        <v-spacer></v-spacer>
                        <v-chip class=""  style="width: 40px; text-align: center;" color="#37AE78" label small text-color="white">
                             {{taskdeTailsObje.approved || 0}}
                        </v-chip>
                    </div>
                </div>
                </div>
                <v-divider></v-divider>
                

</v-card-text>
          
          </div>
        </template>
</lb-dialog>


</div>
</div>
</template>

<script>
// import { watch } from 'vue';
// import BulkChecklistUpload from '../Components/BulkUploadChecklist.vue';
import DragDropfile from "../../../../views/Inputs/DragDropfile.vue"
let styledxlsx = require('sheetjs-style');
export default {
    name: 'financialclose_project_checklist',
    data: function () {
        return {
            right: "financialclose_analysis",
            page_name: 'financialclose_project_checklist',
            custom_layout_success:false,
            metadata: {},
            loading: false,
            tbjson: {},
            tb: [],
            pbcdata: {},
            pbc: [],
            alltaskassigned: true,
            possibleAssertions: ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut-off', 'Classification', 'Presentation'],
            botlist: [
                { name: 'Lease Schedule', value: '6470e55d999d5833d9d58224' },
                { name: 'Borrowing Schedule', value: '6474cf6a1117a96cfd35795d' },
                { name: 'Transaction Matching', value: '6474e49c00b1f12f6a2a8065' },
            ],
            leaseScheduleItems:[
                "BS-ROU Asset",
                "BS-Lease Liability",
                "PL-Amortization Cost",
                "PL-Interest Expense",
                "BS-Security Deposit",
                "BS-SD-Interest Income"
            ],
            configureColumns: ['Priority', 'Category', 'Sub Category', 'Preparer', 'Approver', 'Linked GL(s)', 'Information requests', 'Purpose', 'Instruction', 'Location'],
            tableHeaders: [
                {
                    text: 'Ref',
                    value: 'ref',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Task Description',
                    value: 'description',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Status',
                    value: 'stage',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: "Action",
                    value: 'upload',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'DueDays',
                    value: 'duedate',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'TB amount',
                    value: '__tbtotals',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Schedule Amount',
                    value: 'scheduleAmount',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Difference',
                    value: 'difference',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Category',
                    value: 'category',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Sub Category',
                    value: 'subcategory',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: this.$store.state.LocationHeader || 'Location',
                    value: 'location',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Priority',
                    value: 'priority',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Tags',
                    value: 'tags',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Created On',
                    value: 'created_at',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Preparer',
                    value: 'preparer',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Approver',
                    value: 'approver',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },



                // {
                //     text: 'Due date',
                //     value: 'duedate',
                //     visible: true,
                //     filterable: true,
                //     sortable: true,
                // },
                {
                    text: 'Information Request',
                    value: 'inforrequest',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Action',
                    value: 'action',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },],
                tableHeadersfilter: [
                {
                    text: 'Ref',
                    value: 'ref',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Task Description',
                    value: 'description',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Status',
                    value: 'stage1',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: "Action",
                    value: 'upload',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Due Days',
                    value: 'duedays',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
               
                {
                    text: 'TB amount',
                    value: '__tbtotals',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Schedule Amount',
                    value: 'scheduleAmount',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Difference',
                    value: 'difference',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                 {
                    text: 'Due date',
                    value: 'duedate1',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Category',
                    value: 'category',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Sub Category',
                    value: 'subcategory',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: this.$store.state.LocationHeader || 'Location',
                    value: 'locationName',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Priority',
                    value: 'priority',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Tags',
                    value: 'tags',
                    visible: false,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Created On',
                    value: 'created_at',
                    visible: false,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Preparer',
                    value: 'preparerids',
                    visible: false,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Approver',
                    value: 'approverids',
                    visible: true,
                    filterable: true,
                    sortable: true,
                },
                {
                    text: 'Information Request',
                    value: 'inforrequest',
                    visible: false,
                    filterable: false,
                    sortable: false,
                },
                {
                    text: 'Submission Date',
                    value: 'submited_on',
                    visible: false,
                    filterable: true,
                    sortable: false,
                },
                {
                    text: 'Completion Date',
                    value: 'closed_date',
                    visible: false,
                    filterable: true,
                    sortable: false,
                },
                {
                    text: 'Status Detail',
                    value: 'statusdetail',
                    visible: false,
                    filterable: true,
                    sortable: false,
                },
                {
                    text: 'More Action',
                    value: 'action',
                    visible: true,
                    filterable: false,
                    sortable: false,
                },],
            selectedRows: [],
            checklist: [],
            summary: {},
            managersummary:{}, 
            preparersummary:{},
            approversummary:{},
            id: '',
            userid: '',
            clientId: '',
            usersList: [],
            locationList: [],
            formatedChecklist: [],
            allRowsSelected: false,
            viewtaskdailog: false,
            viewtaskdata: [],
            search: '',
            menuOpen: false,
            OpenBulkUpload: false,
            fileNamesList: [],
            userType: "preparer",
            searchcolumn: "",
            itemsPerPage: 50,
            selectItem: [],
            page: 1,
            pageInput: null,
            tasklistData: [],
            tasklistDataCopy:[],
            showMore: false,
            tabify: 0,
            schedulelinkdialogitems: [],
            tblinkdialogitems: [],
            addeditdialog: false,
            addeditdata: [],
            addeditdataerror: {},
            formdata: {},
            possibleassertions: ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'],
            addeditloading: false,
            selectedTabStage: null,
            categoryAcc: [],
            subcategoryAcc: [],
            priorityList: ['High', 'Medium', 'Low'],
            Alltags: [],
            toDoTaskOnly: false,
            overDueDate:false,
            infomangerrequests: [],
            bulkactions: 0,
            bulkselectedlocations: [],
            showSearch: false,
            searchQuery: '',
            conversationList: [],
            queryMsg: '',
            sendforreviewdrawer: false,
            fileuplaoded: null,
            fileremark: '',
            uploadiwithoutfile: false,
            addlocationanduserflag: 0,
            showlocationanduser: false,
            selectedbot:[],
            bulklinkbotdialog:false,
            leaseScheduleValue:'',
            leaseSchedulebulkValue:[],
            isLocation: false,
            selectaction:'',
            bulkactionuserselected: false,
            bulkactionuserusertype: '',
            possibleusers: [],
            bulkactionuserdialog:false,
            bulkactionuserid: '',
            itemapprover:[
                { value:"associate",name:"Add" },
                { value:"remove",name:"Remove" },
                { value:"replace",name:"Replace" },
                { value:"move",name:"Change to Preparer" }
            ],
            itempreparer:[
                { value:"associate",name:"Add" },
                { value:"remove",name:"Remove" },
                { value:"replace",name:"Replace" },
                { value:"move",name:"Change to Approver" }
            ],
            changetouser:'',
            helpdeskitemnew: false,
            helpdeskitem: {},
            helpdeskdialog:false,
            linkbotdialog:false,
            bulkworkpaperupload:false,
            bulkworkpaper:false,
            searchcustomfilter:'',
            selectgls:[],
            selectedGlCodes:[],
            selectedLocation:"",
            chartAcc:[],
            hoverIndex:false,
            inforeqstage:'',
            linkbotloading:false,
            bulkuploadtemplate:false,
            file: null,
            fileprocessing: {},
            flag: false,
            uploaddata: [],
            dataerror: [],
            dialog: false,
            showdatawitherror:false,
            errorsonly:[],
            activitylogconfig: {
                checklistedit: {  icon: "mdi-pencil", color: "fbcPrimary", displaytext: "Checklist Updated" },
                bulkapprove: {  icon: "mdi-tag-check-outline ", color: "fbcPrimary", displaytext: "Bulk Approve" },
                bulkreject: {  icon: "mdi-close-box-multiple-outline  ", color: "fbcPrimary", displaytext: "Bulk Reject" },
                checklistsentforreview: {  icon: "mdi-file-eye", color: "fbcPrimary", displaytext: "Checklist Sent For Review" },
                fileupload: {  icon: "mdi-file-upload", color: "fbcPrimary", displaytext: "File Upload" },
            },
            resetFlag: false, 
            tasklistfilter:[],
            tasklistfilterCopy:[],
            updatebulkduedate:'',
            bulkduedatedialog:false,
            bulkduedateloading:false,
            tableFilterHeader:{},
            defaultTableHeadersfilter: [],
            defaultTableFilterHeader: {},
            page_details:{},
            statusdetails:{},
            taskdeTailsObje:{},
            taskdeTailsDialog:false,

        }
    },
    components: {
        DragDropfile,
        // BulkChecklistUpload
    },
    created() {
        this.refreshData();
        this.defaultTableHeadersfilter = JSON.parse(JSON.stringify(this.tableHeadersfilter));
        this.defaultTableFilterHeader = JSON.parse(JSON.stringify(this.tableFilterHeader));
    },
    // activated() {
    //     this.refreshData();
    // },
    // mounted(){
    //     this.refreshData();
    // },

    methods: {
        formateData() {
          let ObjectTemp = {};
          let ArrayTemp = [];
          this.tasklistData.forEach((item, index) => {
            ObjectTemp = {
              __keys: index,
              __formatedkey: index,
              _id: item._id,
              indexno: index + 1,
              approver:item.approver,
              approverids: this.getNamesByIds(item.approver),
              assertions:item.assertions,
              bot_result_tags:item.bot_result_tags,
              category:item.category,
              closed_date:this.$nova.formatDate(item?.closed_date),
              conversation:item.conversation,
              conversation_read:item.conversation_read,
              created_at:this.$nova.formatDate(item.created_at),
              created_by:item.created_by?.split('T')[0],
              description:item.description,
              duedate:item.duedate?.split('T')[0],
              duedate1:this.$nova.formatDate(item.duedate),
              duedays:item.duedays,
              instruction:item.instruction,
              location:item.location,
              locationItem:item.locationItem,
              locationName:item.locationName,
              master_id:item.master_id,
              pbc:item.pbc,
              priority:item.priority,
              preparer:item.preparer,
              preparerids:this.getNamesByIds(item.preparer),
              project:item.project,
              purpose:item.purpose,
              ref:item.ref,
              remark:item.remark,
              result:item.result,
              review:item.review,
              stage:item.stage,
              stage1:this.getStageText(item.stage),
              status:item.status,
              statusdetail:this.getStatusText(item.statusdetail),
              subcategory:item.subcategory,
              submited_on:this.$nova.formatDate(item?.submited_on),
              tags:item.tags,
              threshold:item.threshold,
              updated_at:item.updated_at?.split('T')[0],
              updated_by:item.updated_by?.split('T')[0],
              upload:item.upload,
              __pbccount:item.__pbccount,
              __tbtotals:item.__tbtotals,
            };
            ArrayTemp.push(ObjectTemp);
          });
          this.tasklistfilter = ArrayTemp;
          this.tasklistfilterCopy = ArrayTemp;
          console.log(ArrayTemp,"ArrayTemp");
        },
        getNamesByIds(ids) {
            return ids.map((id) => {
                const user = this.usersList.find((u) => u._id === id);
                return user ? user.name : undefined; 
            }).filter((name) => name !== undefined); 
        },
    downloadTemplate(){
      if(this.id) this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/getbulkchecklisttemplate');
      else this.$nova.downloadFile('', {}, '/v2/financialclose/masters/checklist/getbulksubmittemplate');
    },
    cleardata(){
      this.file =  null;
      this.fileprocessing =  {};
      this.flag =  false;
      this.uploaddata =  [];
      this.dataerror =  [];
      this.dialog =  false;
      this.loading =  false;
      this.showdatawitherror = false;
    },
    readFile(key) {
      let file = this.file;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['Description', 'Category', 'Sub Category','Location', 'Assertions', 'Purpose', 'Instruction', 'Preparer', 'Approver', 'Tags', 'Due Days', 'Account No','Priority'];
        // let possibleassertions = ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'];
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "");
        }).then(async (dt) => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            let chartofaccountData = [];
            this.flag = false;
            await this.axios.post("/v2/financialclose/masters/chartofaccount/get")
              .then((dt) => {
                if (dt.data.status === "success") {
                  chartofaccountData.push({ "data": dt.data.data })
                }
              });
            for (let i = 0; i < dt.length; i++) {
              if (i > 0 && dt[i].length>0) {
                const el = dt[i];
                let rowdata = { "description": el[0], "category": el[1], "subcategory": el[2]} 
                rowdata.location = (el[3] || "base").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.assertions = (el[4] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.purpose = el[5]
                rowdata.instruction = el[6] 
                rowdata.preparer = (el[7] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.approver = (el[8] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.tags = (el[9] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.duedays = Number(el[10] || "") || null;
                rowdata.glcode = (el[11] || "").toString().split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.priority = el[12];
                let errors = {};
                if ((rowdata.description || "").toString() === "") errors["description"] = " ";
                if ((rowdata.category || "").toString() === "") errors["category"] = " ";
                if ((rowdata.subcategory || "").toString() === "") errors["subcategory"] = " ";
                if ((rowdata.approver || "").toString() === "") errors["approver"] = " ";
                if ((rowdata.preparer || "").toString() === "") errors["preparer"] = " ";
                if(this.project) delete rowdata.tags;
                else if ((rowdata.tags || []).length === 0) errors["tags"] = " ";
                // let notfoundassertions = [];
                // for (const i of rowdata.assertions) {
                //   if(possibleassertions.indexOf(i) === -1) notfoundassertions.push(i);
                // }
                // if(notfoundassertions.length > 0) errors["assertions"] = notfoundassertions.join(", ") + " assertions not found";
                if (rowdata.preparer.length > 0 && rowdata.approver.length > 0) {
                  let preparers = rowdata.preparer;
                  let approvers = rowdata.approver;
                  let duplicates = [];
                  for (const i of preparers) {
                    if (approvers.indexOf(i.trim()) > -1) duplicates.push(i);
                  }
                  if (duplicates.length > 0) errors["preparer"] = "'" + duplicates.join(",") + "' cannot be both preparer and approver";
                }
                // if(rowdata.location.length===0) errors["location"]="Location is required"
                for(const i of rowdata.glcode){
                  if (rowdata.glcode[i] != null && rowdata.glcode[i] !== "") {
                    let isGLValid = chartofaccountData[0].data.filter(obj => obj['glcode'] == rowdata.glcode[i]) || "";
                    if (isGLValid === "" || isGLValid.length === 0) {
                      errors["glcode"] = rowdata.glcode[i] + " Invalid GL Code";
                    }
                  }
                }
                if (Object.keys(errors).length > 0) this.flag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.errorsonly = this.dataerror
            this.dialog = true;
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    readFilesinglebulk(key) {
        console.log(key);
        console.log(this.file);

    let files = this.file;
  if (Array.isArray(files) && files.length > 0) {
    this.loading = true;
    Promise.all(
      files.map(async (file, index) => {
        if ((file || {}).name && (this.fileprocessing[index] || 0) === 0) {
          this.fileprocessing[index] = 1;
          let ext = ((file || {}).name || "").split(".").pop();

          // Validate file extension
          if (!["xlsx", "xls", "xlsm", "xlsb"].includes(ext)) {
            throw new Error(`File '${file.name}' has unsupported extension. Only xlsx, xls, xlsm, xlsb are allowed.`);
          }

          // Read Excel file
          let dt = await this.$nova.readExcelFile(file, "TOP SHEET");
          console.log(dt,"dtdtdtdtdt");
         let headings =  [
    "Task No ",
    "Task description",
    "Location",
    "Particulars",
    "Amount",
    "File Reference"
];
//         let headings =  [
//     "Task No ",
//     "Task description",
//     "Location",
//     "Particulars",
//     "Amount",
//     "File Reference"
// ];

          // Validate headings
          if (JSON.stringify(dt[0] || []) !== JSON.stringify(headings)) {
            throw new Error(
              `File '${file.name}' is missing required columns or they are not in the correct order. Expected: '${headings.join("', '")}'`
            );
          }

          console.log(`File '${file.name}' passed validation.`);
        }
      })
    )
      .then(() => {
        console.log("All files validated successfully.");
      })
      .catch((err) => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })
      .finally(() => {
        this.file = null; // Reset file array
        this.fileprocessing = {}; // Reset processing state
        this.loading = false; // Reset loading state
      });
  } else {
    console.log("No files to validate.");
  }
        
        
    //   let file = this.file;
    //   if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
    //     let headings = ['Description', 'Category', 'Sub Category','Location', 'Assertions', 'Purpose', 'Instruction', 'Preparer', 'Approver', 'Tags', 'Due Days', 'Account No'];
    //     // let possibleassertions = ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'];
    //     this.loading = true;
    //     this.fileprocessing[key] = 1;
    //     let ext = ((file || {}).name || "").split(".").pop();
    //     Promise.resolve().then(() => {
    //       if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
    //       return this.$nova.readExcelFile(file, "");
    //     }).then(async (dt) => {
    //       if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {

    //       } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
    //     }).catch(err => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     }).finally(() => {
    //       this.file = null;
    //       this.fileprocessing[key] = 0;
    //       this.loading = false;
    //     });
    //   }
    },
    uploadData() {
      this.loading = true;
      let url = "/v2/financialclose/masters/checklist/bulkadd";
      if(this.id) url = "/v2/financialclose/analysis/bulkaddchecklist/"+this.id;
      this.axios.post(url, { data: this.uploaddata }).then(dt => {
        if (dt.data.status === "success") {
          this.bulkuploadtemplate = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Checklist uploaded successfully");
          this.refreshData()
        } else throw dt.data.message || "Error uploading Tasklist";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        this.resetFlag = !this.resetFlag;
        console.log(err);
      }).finally(() => {
        this.resetFlag = !this.resetFlag;
        this.loading = false;
        this.uploaddata=[],
        this.showdatawitherror=false,
        this.dataerror=[],
        this.flag=false
      })
    },
    bulkworkpaperuploadFun(){
        this.sendforreviewdrawer = true; 
        this.bulkworkpaper = true;
        this.resetFlag = !this.resetFlag;
        this.fileNamesList = [];
        this.fileremark = "";
    },
    updateFilesBulk(newFilesBulk) {
      this.file = newFilesBulk[0]; 
      console.log("edrfgfewefgb",newFilesBulk);
           
      this.readFile('file')
    },

        getGlname(id){
           return this.tb.find((item)=> item._id === id)
        },

        toggleSearch() {
            this.showSearch = !this.showSearch;  // Toggle search box visibility
        },
        async getData() {
    try {
        this.loading = true;

        // Step 1: Get the main analysis details
        const analysisResponse = await this.axios.post(`/v2/financialclose/analysis/getdetails/${this.id}`);
        const dt = analysisResponse.data;

        if (dt.status === "success" && dt.data.length > 0) {
            const data = dt.data[0] || {};
            this.metadata = data.metadata || {};
            this.tasklistData = data.checklist || [];
            this.tasklistDataCopy = [...this.tasklistData];
            this.tb = data.tb || [];
            this.pbc = data.pbcitems || [];
            this.checklist = data.checklist || [];
            this.managersummary = data.summary?.manager || {};
            this.preparersummary = data.summary?.preparer || {};
            this.approversummary = data.summary?.approver || {};

            // Determine the summary based on userType
            if (this.userType === 'preparer') {
                this.summary = data.summary?.preparer || {};
            } else if (this.userType === 'approver') {
                this.summary = data.summary?.approver || {};
            } else {
                this.summary = data.summary?.manager || {};
            }

            // Handle table headers visibility
            if (data.tb.length === 0) {
                this.tableHeadersfilter[7].visible = false;
                this.tableHeadersfilter[5].visible = false;
                this.tableHeadersfilter[6].visible = false;
            }
        }

        // Step 2: Get form data
        const formDataResponse = await this.axios.post("/v2/financialclose/analysis/getformdata");
        const formData = formDataResponse.data;
        this.usersList = formData.data[0].users;

        // Step 3: Get location data
        const locationResponse = await this.axios.post("/v2/financialclose/location/get");
        this.locationList = locationResponse.data.data;

        // Step 4: Get project threshold data
        const projectThresholdResponse = await this.axios.post(`/v2/financialclose/project/threshold/get/${this.id}`);
        if (projectThresholdResponse.data.status === "success") {
            this.checklist = this.checklist.map(obj1 => {
                obj1.threshold = 'NA';
                projectThresholdResponse.data.data.forEach(obj2 => {
                    if (obj2.location === obj1.location) {
                        obj1.threshold = obj2.threshold;
                    }
                });
                return obj1;
            });
        }

        // Step 5: Get master threshold data
        const masterThresholdResponse = await this.axios.post("/v2/financialclose/masters/threshold/get");
        if (masterThresholdResponse.data.status === "success") {
            this.checklist = this.checklist.map(obj1 => {
                if (obj1.threshold === 'NA') {
                    obj1.threshold = 0;
                    masterThresholdResponse.data.data.forEach(obj2 => {
                        if (obj2.location === obj1.location) {
                            obj1.threshold = obj2.threshold;
                        }
                    });
                }
                return obj1;
            });
        }

        // Step 6: Get manager requests
        const managerRequestsResponse = await this.axios.post(`/v2/financialclose/pbc/project/getrequests/${this.metadata.pbcproject}`);
        if (managerRequestsResponse.data.status === "success") {
            this.infomangerrequests = managerRequestsResponse.data.data;
        }

        // Step 7: Get chart of account data
        const chartAccountResponse = await this.axios.post("/v2/financialclose/masters/chartofaccount/get");
        this.chartAcc = chartAccountResponse.data.data;
        this.categoryAcc = this.chartAcc.map((x) => x.category);
        this.subcategoryAcc = this.chartAcc.map((y) => y.subcategory);

        // const payload = {
        //     page:this.page_name,
        // };
        // const customlayout = await this.axios.post('/v2/financialclose/analysis/getcustomlayout',{data:payload});
        // this.custom_layout_success = false;
        // if (customlayout.data.status === "success" && customlayout.data.data.length > 0) {
        //     console.log(customlayout.data.data[0].custom_layout,"customlayout.data.data");
        //     this.custom_layout_success = true;
        //     this.tableHeadersfilter = customlayout.data.data[0]?.custom_layout;
        //     this.tableFilterHeader = customlayout.data.data[0]?.filter;
        // }

    } catch (err) {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.error(err);
    } finally {
        // Final actions
        console.log("summary", this.checklist);
        this.calculateDetails();
        this.loading = false;
        this.formateData();
        this.getCustomLayout();
        this.getStatus();
    }
},
getTaskDeatils(user){
        this.taskdeTailsObje = {};
        console.log(this.statusdetails,"this.statusdetails");
        if (this.statusdetails[user].length > 0) {
            this.taskdeTailsObje = this.statusdetails[user][0];
        } else {
            console.log("ddd");
            
        }
        
        this.taskdeTailsDialog = true;
},

async getStatus(){
     try {
     const response = await this.axios.post('/v2/financialclose/analysis/getduedayswisecount/' + this.metadata._id);

    if (response.data.status === "success" && response.data.data.length > 0) {
        this.statusdetails =  response.data.data[0];
        console.log(this.statusdetails,"statusdetails");
        
    } else {
      console.error("Error fetching custom getStatus:");            
    }
    } catch (error) {
        console.error("Error fetching custom getStatus:", error);
    }
},
async getCustomLayout(){
    this.page_details =  {cycle_id:this.id,pagename:this.page_name};
    this.custom_layout_success = false;
        try {
        const payload = {
        page: this.page_name,
    };
     const response = await this.axios.post('/v2/financialclose/analysis/getcustomlayout', { data: payload });

    if (response.data.status === "success" && response.data.data.length > 0) {
        this.custom_layout_success = true;
        this.tableHeadersfilter = response.data.data[0].custom_layout.length > 0 ? response.data.data[0].custom_layout : this.defaultTableHeadersfilter;
        this.tableFilterHeader = response.data.data[0]?.filter[this.id] || {};
    } else {
        this.custom_layout_success = true;
        this.tableHeadersfilter = JSON.parse(JSON.stringify(this.defaultTableHeadersfilter));
        this.tableFilterHeader = JSON.parse(JSON.stringify(this.defaultTableFilterHeader));
        console.log("Reset to default tableHeadersfilter and tableFilterHeader values.");        
    }
    } catch (error) {
        console.error("Error fetching custom layout:", error);
    }
    },
        // api call for all - tasklist data, location, 
        // getData() {
        //     this.loading = true;

        //     return this.axios.post(`/v2/financialclose/analysis/getdetails/${this.id}`)
        //         .then(dt => {
        //             if (dt.data.status === "success" && dt.data.data.length > 0) {
        //                 let data = dt.data.data[0] || {};
        //                 this.metadata = data.metadata || {};
        //                 this.tasklistData = data.checklist || [];
        //                 this.tasklistDataCopy = data.checklist || [];
        //                 this.tb = data.tb || [];
        //                 this.pbc = data.pbcitems || [];
        //                 this.checklist = data.checklist || [];
        //                 this.managersummary = data.summary?.manager || {};
        //                 this.preparersummary = data.summary?.preparer || {};
        //                  this.approversummary = data.summary?.approver || {};

        //                 if (this.userType === 'preparer') {
        //                     this.summary = data.summary?.preparer || {};
        //                 }else if (this.userType === 'approver') {
        //                     this.summary = data.summary?.approver || {};
        //                 } else {
        //                     this.summary = data.summary?.manager || {};
        //                 }
        //                 // this.summary = data.summary?.preparer || {};
        //                 // this.summary = data.summary || {};
        //                 // if(data.tb.length === 0){
        //                 //     this.tableHeaders[7].visible=false;
        //                 //     this.tableHeaders[5].visible=false;
        //                 //     this.tableHeaders[6].visible=false
        //                 // }
        //                 if(data.tb.length === 0){
        //                     this.tableHeadersfilter[7].visible=false;
        //                     this.tableHeadersfilter[5].visible=false;
        //                     this.tableHeadersfilter[6].visible=false
        //                 }
        //             }
        //         })
        //         .catch(err => {
        //             this.$store.commit("sbError", err.message || err || "Unknown error!");
        //             console.log(err);
        //         })
        //         .then(() => {
        //             return this.axios.post("/v2/financialclose/analysis/getformdata");
        //         })
        //         .then(dt => {
        //             console.log("getformdata", dt.data.data[0].locations);
        //             this.usersList = dt.data.data[0].users;
        //         })
        //         .then(() => {
        //             return this.axios.post("/v2/financialclose/location/get")
        //                 .then(dt => {
        //                     this.locationList = dt.data.data;
        //                 });
        //         })
        //         .then(() => {
        //             return this.axios.post(`/v2/financialclose/project/threshold/get/${this.id}`)
        //                 .then(dt => {
        //                     if (dt.data.status === "success") {
        //                         this.checklist = this.checklist.map(obj1 => {
        //                             obj1.threshold = 'NA';
        //                             dt.data.data.forEach(obj2 => {
        //                                 if (obj2.location === obj1.location) {
        //                                     obj1.threshold = obj2.threshold;
        //                                 }
        //                             });
        //                             return obj1;
        //                         });
        //                     }
        //                 });
        //         })
        //         .then(() => {
        //             return this.axios.post("/v2/financialclose/masters/threshold/get")
        //                 .then(dt => {
        //                     if (dt.data.status === "success") {
        //                         this.checklist = this.checklist.map(obj1 => {
        //                             if (obj1.threshold === 'NA') {
        //                                 obj1.threshold = 0;
        //                                 dt.data.data.forEach(obj2 => {
        //                                     if (obj2.location === obj1.location) {
        //                                         obj1.threshold = obj2.threshold;
        //                                     }
        //                                 });
        //                             }
        //                             return obj1;
        //                         });
        //                     }
        //                 });
        //         })
        //         .then(() => {
        //             console.log("metadata", this.metadata);
        //             return this.axios.post(`/v2/financialclose/pbc/project/getrequests/${this.metadata.pbcproject}`)
        //                 .then(dt => {
        //                     if (dt.data.status === "success") {
        //                         this.infomangerrequests = dt.data.data
        //                     }
        //                 });
        //         }).then(() => {
        //             this.axios
        //                 .post("/v2/financialclose/masters/chartofaccount/get")
        //                 .then((ele) => {
        //                 this.chartAcc = ele.data.data
        //                 this.categoryAcc = this.chartAcc.map((x) => x.category);
        //                 this.subcategoryAcc = this.chartAcc.map((y) => y.subcategory);
        //                 });
        //         })
        //         .catch(err => {
        //             this.$store.commit("sbError", err.message || err || "Unknown error!");
        //         })
        //         .finally(() => {
        //             console.log("summary", this.checklist);
        //             this.calculateDetails();
        //             this.loading = false;
        //             this.formateData();
        //         });
        // },

        // calculateDetails of checklist 
        calculateDetails() {
            let tbtotals = {};
            let closing_amount_to_subtract = []
            let tb_cat_subcat_sum = {};
            for (const i of this.tb) {
                this.tbjson[i._id] = i;
                let closing_info = { category: i.category, subcategory: i.subcategory, closing_amount: 0 }
                if (i.reference_detail.length > 0) {
                    for (const j of (i.reference_detail || [])) {
                        tbtotals[j._id] = tbtotals[j._id] || { total: 0, items: [] };
                        tbtotals[j._id].items.push(i);
                        tbtotals[j._id].total = tbtotals[j._id].total + i.closing_amount || 0;
                        // console.log("tbtotals=>>", tbtotals);
                        closing_info.closing_amount = tbtotals[j._id].total
                    }
                    closing_amount_to_subtract.push(closing_info)
                }
                const category = i.category;
                const subcategory = i.subcategory;
                const location = 'location' in i ? i.location : '';
                const key = `${category}-${subcategory}`;
                tb_cat_subcat_sum[key] = tb_cat_subcat_sum[key] || {
                    sum: 0,
                    category: category,
                    subcategory: subcategory,
                    locations: {}
                };
                tb_cat_subcat_sum[key].sum += i.closing_amount || 0;
                if (location) {
                    tb_cat_subcat_sum[key].locations[location] = (tb_cat_subcat_sum[key].locations[location] || 0) + (i.closing_amount || 0);
                }
            }
            for (const ele of closing_amount_to_subtract) {
                if (tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`]) {
                    tb_cat_subcat_sum[`${ele.category}-${ele.subcategory}`].sum -= ele.closing_amount;
                }
            }
            this.pbcdata = {};
            let pbccount = {};
            for (const i of (this.pbc || [])) {
                this.pbcdata[i._id] = i;
                for (const j of (i.reference || [])) {
                    pbccount[j] = pbccount[j] || { total: 0, completed: 0 };
                    pbccount[j].total++;
                    if (i.stage === 2) pbccount[j].completed++;
                }
            }
            for (const x of this.tasklistData) {
                if ((x.preparer || []).length === 0 || (x.approver || []).length === 0) this.alltaskassigned = false;
                x.__pbccount = pbccount[x._id] || {};
                if (pbccount[x._id]) console.log(pbccount[x._id]);
                if (!Object.keys(tbtotals[x._id] || {}).includes("total")) {
                    tbtotals[x._id] = { total: 0, items: [] };
                    let tbitem = this.tb.filter(item => {
                        if (Object.keys(item).includes("location")) {
                            if (item.category === x.category && item.subcategory === x.subcategory && item.location[0] === x.location) {
                                return item
                            }
                        }
                    });
                    if (Object.keys(tb_cat_subcat_sum).includes(`${x.category}-${x.subcategory}`)) {
                        if (x.location in tb_cat_subcat_sum[`${x.category}-${x.subcategory}`].locations) {
                            tbtotals[x._id] = { total: 0, items: [] };
                            for (const j of tbitem) {
                                tbtotals[x._id].items.push(j);
                                tbtotals[x._id].total = tbtotals[x._id].total + j.closing_amount || 0;
                            }
                        }
                    }
                }
                if ((x?.location)?.length > 0) {
                    x.locationItem = (this.locationList.filter(obj => obj["_id"] === x.location)) || [{ "name": "" }];
                    if (x.locationItem.length > 0) {
                        x.locationName = x.locationItem[0].name || "";
                    }
                    else x.locationName = "";
                }
                x.__tbtotals = tbtotals[x._id] || {};
            }
            // this.formatChecklistData();

            console.log("checklisstttttt", this.checklist);
        },
        // toglle multiselect items in table
        toggleSelectAll() {
            this.allRowsSelected = !this.allRowsSelected
            if (this.allRowsSelected) {
                this.selectedRows = [...this.formatedChecklist];
            } else {
                this.selectedRows = [];
            }
        },
        // refress data 
        refreshData() {
            this.id = this.$route.params.id || "";
            this.userid = this.$store.state.user.id;
            this.clientId = this.$store.state.client;
            console.log(this.$store)
            this.getData();
        },
        // viewtaskdetails
        viewTaskDetails(item) {
            this.viewtaskdata = { ...item }
            console.log("sderftgyhuiouy", this.viewtaskdata);
            this.populateschedulelinkdialogitems(item, false);
            this.populatetblinkdialogitems(item, false);
            this.viewtaskdata["isMatched"] = Math.abs((this.tblinkdialogchecklistdata.__tbtotals?.total - Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || this.tblinkdialogchecklistdata.__tbtotals?.total || Number(this.schedulelinkdialogchecklistdata.result.value?.total_value) || 0)) < item.threshold ? true : false;
            this.infomanagerReq(item);
            this.OpenBulkUploadBox(item);
            this.viewtaskdailog = true
        },

    infomanagerReq(dt) {
    let requst = this.infomangerrequests?.filter((item) => {
    const searchId = dt.master_id;
        const selectlistMatch = Array.isArray(item.selectlist)
            ? item.selectlist.filter(subItem => subItem.selectlist === searchId && subItem.location === dt.location)
            : [];
        const referenceMatch = Array.isArray(item.reference) && item.reference.includes(dt._id);
        return selectlistMatch.length > 0 || referenceMatch;
    });

    this.viewtaskdata["linkedInformationReq"] = requst;
    this.inforeqstage = requst.length > 0 
        ? (requst.filter(item => item.stage === 2).length / requst.length) * 100 
        : 0;
},
        // information request mapping in task details 
        // infomanagerReq(dt) {
        //     let requst = this.infomangerrequests?.filter((item) => {
        //         const searchId = dt.master_id;
        //         const selectlistMatch = item.selectlist.filter(subItem => subItem.selectlist === searchId && subItem.location === dt.location);
        //         const referenceMatch = item.reference.includes(dt.id);
        //         return selectlistMatch.length > 0 ? selectlistMatch : referenceMatch
        //     });
        //     this.viewtaskdata["linkedInformationReq"] = requst;
        //     this.inforeqstage = (requst.filter(item => item.stage === 2).length/requst.length)*100 ;
        // },
        // download ppt for all closed task
        downloadPPT() {
            if (!this.alltaskassigned) this.$store.commit("sbError", "Assign all tasks before generating the PPT");
            else if (this.alltaskopen) this.$store.commit("sbError", "No task closed to generate the PPT");
            else this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/downloadppt/' + this.metadata._id);
        },
        // download excel
        downloadExcel() {
            let dt = [
                [undefined],
                [
                    undefined,
                    { v: "Tasklist Status Tracker" , s: { font: { bold: true, sz: 20 } } },
                ],
                [
                    undefined,
                    { v: this.metadata.description, s: { font: { bold: true, sz: 14 } } },
                ],
                [
                    undefined,
                    { v: this.$nova.formatDate(this.metadata.from) + " - " + this.$nova.formatDate(this.metadata.from), s: { font: { bold: true, sz: 14 } } },
                ],
                [
                    undefined
                ],
                [
                    undefined,
                    { v: "Reference", s: { font: { bold: true } } },
                    { v: "Task Description", s: { font: { bold: true } } },
                    { v: "Category", s: { font: { bold: true } } },
                    { v: "Sub Category", s: { font: { bold: true } } },
                    { v: "Location", s: { font: { bold: true } } },
                    { v: "Assertions", s: { font: { bold: true } } },
                    { v: "Due Date", s: { font: { bold: true } } },
                    { v: "Remark", s: { font: { bold: true } } },
                    { v: "Amount as per Trial Balance", s: { font: { bold: true } } },
                    { v: "Amount as per Schedule", s: { font: { bold: true } } },
                    { v: "Difference", s: { font: { bold: true } } },
                    { v: "Purpose", s: { font: { bold: true } } },
                    { v: "Instruction", s: { font: { bold: true } } },
                    { v: "Preparer", s: { font: { bold: true } } },
                    { v: "Approver", s: { font: { bold: true } } },
                    { v: "Submission Date", s: { font: { bold: true } } },
                    { v: "completion Date", s: { font: { bold: true } } },
                    { v: "Status", s: { font: { bold: true } } },
                ]];
            for (const v of this.tasklistfilter) {
                dt.push([
                    undefined,
                    v.ref,
                    v.description,
                    v.category,
                    v.subcategory,
                    this.getLocationById(v.location),
                    (v.assertions || []).join(", "),
                    { v: (v.subcategory) ? this.$nova.formatDate(v.duedate) : '', z: 'dd-mmm-yyyy', t: 'd' },
                    (v.remark),
                    { v: ((v.__tbtotals || {}).total || 0).toFixed(0), z: '#,##', t: 'n' },
                    { v: (Number((v.result || {}).value?.total_value) || 0).toFixed(0), z: '#,##', t: 'n' },
                    { v: (((v.__tbtotals || {}).total || 0) - (Number((v.result || {}).value?.total_value) || 0)).toFixed(0), z: '#,##', t: 'n' },
                    v.purpose,
                    v.instruction,
                    this.getNameByIds(v.preparer),
                    this.getNameByIds(v.approver),
                    v.submited_on,
                    v.closed_date,
                    this.getStageText(v.stage)
                ])
            }

            // Extract Data (create a workbook object from the table)
            let workbook = styledxlsx.utils.book_new();
            let worksheet = styledxlsx.utils.aoa_to_sheet(dt);
            styledxlsx.utils.book_append_sheet(workbook, worksheet, "Tasklist");
            styledxlsx.writeFile(workbook, this.metadata.description + " - Tasklist.xlsx");
        },

        workpaperTemplate() {
            this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/gettasklisttemplate');
        },

        getNameByIds(ids) {
             for (const i of ids) {
            let locationname = this.usersList.find((x)  => x._id === i);
            return locationname ? locationname.name : ""
            }
        },

        getUserList(x) {
            let rdt = [];
            for (const i of x) {
                rdt.push(this.usersList[i])
            }
            return rdt.join(", ")
        },

        getStageText(x) {
            if (x === 0) return "Pending Submission";
            else if (x === 1) return "Processing";
            else if (x === 2) return "Pending Approval";
            else if (x === 3) return "Closed Task";
            else return "Unknown";
        },
        getStatusText(x) {
            if (x === 'pending_not_due') return "Pending Submission Not Due";
            else if (x === 'pending_over_due') return "Pending Submission Overdue";
            else if (x === 'pending_due') return "Pending Submission Due";
            else if (x === 'pendingapproval_due') return "Pending Approval Due";
            else if (x === 'pendingapproval_not_due') return "Pending Approval Not Due";
            else if (x === 'pendingapproval_over_due') return "Pending Approval Overdue";
            else return "approved";
        },
        // delete any task in tasklist
        deleteChecklistItem(item) {
            let url = "/v2/financialclose/analysis/deletechecklist/" + this.id + '/c/' + item._id;
            this.axios.post(url, {}).then(dt => {
                if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "Tasklist item deleted");
                    this.refreshData();
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
            })
        },
        // link bot for ahy single task
        linkBot() {
            this.linkbotloading = true;
            let url = "/v2/financialclose/analysis/linkbot/" + this.id;
            this.axios.post(url, { data: [{ botid: this.selectedbot, task_id: this.addeditdata._id, bot_result_tags: this.leaseScheduleValue }] }).then(dt => {
                if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "Bot Linked");
                    this.linkbotdialog = false;
                    this.checklist[this.addeditdata.__key].botid = this.selectedbot;
                    this.selectedbot = "";
                    this.calculateDetails();
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.linkbotloading = false;
            })
        },
        // link bot for multi selected task
        async bulklinkBot() {
            try {
                this.bulklinkbotloading = true;
                let successMessageSent = false;
                const url = "/v2/financialclose/analysis/linkbot/" + this.id;
                const requests = Object.keys(this.addeditdata).map(async (key) => {
                    const value = this.addeditdata[key];
                    const response = await this.axios.post(url, {
                        data: [{ botid: this.selectedbot, task_id: value, bot_result_tags: this.leaseSchedulebulkValue[value] }],
                    });
                    if (response.data.status === "success" && !successMessageSent) {
                        this.$store.commit("sbSuccess", "Bulk Bot Linked");
                        successMessageSent = true;
                        this.bulklinkbotdialog = false;
                        this.leaseSchedulebulkValue = {};
                        // this.checklist[this.addeditdata.__key].botid = this.selectedbot;
                        this.selectedbot = "";
                        this.calculateDetails();
                        this.getData();
                        this.bulklinkbotloading = false;
                    } else {
                        throw Error(response.data.message || "Unknown error");
                    }
                });
                await Promise.all(requests);
                console.log("All requests resolved successfully");
            } catch (err) {
                //this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.error(err);
            } finally {
                this.bulklinkbotloading = false;
            }
        },
        // unlink bot for single task
        unlinkBot(v) {
            this.loading = true;
            let url = "/v2/financialclose/analysis/unlinkbot/" + this.id;
            this.axios.post(url, { data: [v._id] }).then(dt => {
                if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "Bot Unlinked");
                    this.checklist[v.__key].botid = "";
                    this.selectedbot = "";
                    this.calculateDetails();
                    this.getData();
                    this.loading = false;
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        // unlink bot for bulk task
        bulkunlinkBot(v) {
            this.loading = true;
            let url = "/v2/financialclose/analysis/unlinkbot/" + this.id;
            this.axios.post(url, { data: [v] }).then(dt => {
                if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "Bot Unlinked");
                    //  this.checklist[v.__key].botid  = "";
                    this.selectedbot = "";
                    this.calculateDetails();
                    this.getData();
                    this.loading = false;
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        bulkUpdatedueDate(item){
            this.updatebulkduedate = "";
            this.bulkduedatedialog = true;
            this.bulkduedateloading = true;
            console.log(item); 
        },
        bulkduedateFun(selecteditem,date){
            
            let fdt = { duedate:date,_id: selecteditem.map(item => item._id) };
            console.log(fdt);

            this.loading = true;
            let url = "/v2/financialclose/analysis/bulkupdateduedate/" + this.id;
            this.axios.post(url, { data: fdt }).then(dt => {
                if (dt.data.status === "success") {
                    this.$store.commit("sbSuccess", "Updated Due Date");
                    // this.checklist[v.__key].botid = "";
                    // this.selectedbot = "";
                    // this.calculateDetails();
                    this.getData();
                    this.bulkduedatedialog = false;
                    this.loading = false;
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
            
        },
        // tb amount amount calculation for table 
        populatetblinkdialogitems(data = {}, opendialog = true) {
            this.tblinkdialogitems = [];
            this.tblinkdialogitems = (data.__tbtotals || {}).items || [];
            this.tblinkdialogchecklistdata = data;
            if (opendialog) this.tblinkdialog = true;
        },
        //  schemdule amount calculation for table
        populateschedulelinkdialogitems(data = {}, opendialog = true) {
            this.schedulelinkdialogitems = [];
            this.schedulelinkdialogitems = data.result?.value?.fc_table || [];
            this.schedulelinkdialogchecklistdata = data;
            if (opendialog) this.schedulelinkdialog = true;
        },
        // On open bulkupload add filelist 
       async OpenBulkUploadBox(dt) {
            console.log("fileid", dt.upload);
            if (dt.upload) {
                if ((dt.stage === 2 || dt.stage === 3) && dt.upload) {
               await this.axios.post("/v2/upload/filenames/" + dt?.upload).then(dt => {
                    this.fileNamesList = dt.data.data
                    console.log("filedetilas", dt);

                }).catch(err => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
            }
            } else
            {
                this.fileNamesList = '';
            }

            
        },
        // change user type
        overDueDateFun(){
            this.overDueDate = !this.overDueDate
        },
        filterListByUser(user) {
            this.userType = user
            this.selectItem = [];
        if (user === 'approver') {
            this.summary = this.approversummary;
            this.tableHeadersfilter[16].visible = false;
            this.tableHeadersfilter[15].visible = true; 
        } else if (user === 'preparer') {
            this.summary = this.preparersummary;
            this.tableHeadersfilter[15].visible = false;
            this.tableHeadersfilter[16].visible = true; 
        } else{
            this.summary = this.managersummary; 
            this.tableHeadersfilter[15].visible = true;
            this.tableHeadersfilter[16].visible = true;
        }
        },
        // add header
        // addTableHeader(item) {
        //     this.tableHeaders.find((x) => x === item ? x.visible = !x.visible : "")
        // },
        addTableHeader(item) {
            this.tableHeadersfilter.find((x) => x === item ? x.visible = !x.visible : "")
        },
        select(isSelected) {
            if (isSelected) {
                this.selectItem = this.paginatedFilteredItems
            } else {
                this.selectItem = [];
            }
        },
        getItemData(item, key) {
            return item[key];
        },
        updatePage() {
            this.page = this.pageInput; // Update the pagination component
        },
        handlePageInput(value) {
            // Ensure that the value is within the valid range
            const pageValue = parseInt(value, 10);
            if (pageValue > this.pagecount) {
                this.pageInput = this.pagecount;
            } else if (pageValue < 1) {
                this.pageInput = 1;
            } else {
                this.pageInput = pageValue;
            }
        },
        getLocationById(id) {
            let locationname = this.locationList.find((location) => location._id === id)
            return locationname ? locationname.name : ""
        },
        getNameById(id) {
            let locationname = this.usersList.find((location) => location._id === id)
            return locationname ? locationname.name : ""
        },

        // edit task 
        addEditForm(dt = {}) {
            console.log(dt);
            this.addeditdataerror = {};
            this.addeditdata = { ...dt };
            console.log(JSON.stringify(this.addeditdata))
            this.selectedGlCodes = []
            this.defaultGlCodes = this.addeditdata.glcode ?? [];
            // for (const i of this.addeditdata.__tbtotals?.items || []) {
            //     console.log(this.addeditdata.__tbtotals?.items,"selected gls")
            //     // this.selectedGlCodes.push(this.paginatedFilteredItems.filter(item => item._id === i._id));
            // }
            this.selectgls= this.addeditdata.__tbtotals?.items.map((item)=> item._id)
            //this.addeditdata.location = "64510941c50ca87e50dcec675";
            this.isLocationSelected = false;
            if (dt.location && dt.approver && dt.preparer) {
                this.showlocationanduser = true
            }
            this.populatetblinkdialogitems(dt,false);
            this.addeditdialog = true;
        },
        finaluserlist() {
            let nl = [];
            for (const i of this.metadata.managerlist) {
                let ud = { ...this.userlist[i] };
                ud.type = "manager";
                ud._id = this.userlist[i]._id;
                ud.name = this.userlist[i].name;
                nl.push(ud);
            }
            for (const i of this.metadata.userlist) {
                let ud = { ...this.userlist[i] };
                ud.type = "general";
                ud._id = this.userlist[i]._id;
                ud.name = this.userlist[i].name;
                nl.push(ud);
            }
            return nl;
        },
        // edit task upadte api 
        addEditChecklistItem() {
            this.addeditloading = true;
            let type = "add";
            let url = "/v2/financialclose/analysis/addchecklist/" + this.id;
            if (this.addeditdata._id) {
                type = "edit";
                url = "/v2/financialclose/analysis/editchecklist/" + this.addeditdata._id;
            }
            // this.addeditdata = {
            //     description: this.addeditdata.description || "",
            //     category: this.addeditdata.category || "",
            //     subcategory: this.addeditdata.subcategory || "",
            //     duedate: this.addeditdata.duedate || "",
            //     purpose: this.addeditdata.purpose || "",
            //     instruction: this.addeditdata.instruction || "",
            //     priority: this.addeditdata.priority || "",
            //     tags: this.addeditdata.tags || [],
            //     assertions: this.addeditdata.assertions,
            //     approver: this.addeditdata.approver || [],
            //     location: this.addeditdata.location || "",
            //     preparer: this.addeditdata.preparer || [],
            //     _tblinks: this.addeditdata._tblinks || [],
            //     _tbtotals: this.addeditdata._tbtotals || [],
            // }
            let ndt = this.addeditdata;
            let tblinks = [];
            for (const i of ndt.__tbtotals?.items || []) {
                tblinks.push(i._id);
            }
            ndt.__tblinks = tblinks;
            delete ndt._id;
            this.axios.post(url, { data: ndt }).then(dt => {
                if (dt.data.status === "success") {
                    if (type === "add") this.$store.commit("sbSuccess", "Tasklist item added");
                    else this.$store.commit("sbSuccess", "Tasklist item updated");
                    this.addeditdialog = false;
                    delete ndt.__tblinks;
                    this.selectedGlCodes= [];
                    this.selectgls=[];
                    this.viewtaskdialog = false;
                    this.refreshData();
                } else {
                    if (dt.data.data.length > 0) {
                        if (dt.data.data[0].index0) this.addeditdataerror = dt.data.data[0].index0;
                        this.$store.commit("sbError",this.addeditdataerror|| "Unknown error!");
                    } else throw new Error(dt.data.message);
                }
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.addeditloading = false;
            })
        },
        filterWithTask(x) {
            this.selectedTabStage = x
        },
        customStageOrder(arr) {
            const stageOrder = [2, 0, 3];
            return arr.sort((a, b) => {
                const aIndex = stageOrder.indexOf(a.stage);
                const bIndex = stageOrder.indexOf(b.stage);
                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;
                return aIndex - bIndex;
            });
        },
        bulklocationActions() {
            this.$refs.bulkmenu.isActive = false;
            this.bulkactions = 0
        },
        getConversation(task) {

            this.axios
                .post('/v2/financialclose/analysis/getconversation/' + this.metadata._id + '/c/' + task._id)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.conversationList = dt.data.data;
                        // if (mark) this.markAsRead();
                    } else throw Error(dt.data.message || "Unknown error")
                    // console.log(this.conversationList);
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .finally(() => {
                    // this.scrollToBottom();

                    const chatContainer = this.$refs.chatContainer;
                    if (chatContainer) {
                    chatContainer.scrollTop = chatContainer?.scrollHeight;
                    }
                });

        },


        sendQuery(task) {
            if (this.queryMsg) {
                // this.loading = true;
                let dataObject = {
                    type: "string",
                    value: this.queryMsg,
                    status: "",
                };
                // console.log(dataObject);
                let successflag = false;
                this.axios
                    .post('/v2/financialclose/analysis/addconversation/' + this.metadata._id + '/c/' + task._id, { data: dataObject })
                    .then((dt) => {
                        if (dt.data.status === "success") {
                            successflag = true;
                            // this.$store.commit("sbSuccess", "successfully sended");
                            this.queryMsg = "";

                        } else throw Error(dt.data.message || "Unknown error")
                    })
                    .catch((err) => {
                        this.$store.commit("sbError", err.message || err || "Unknown error!");
                        console.log(err);
                    })
                    .finally(() => {
                        // this.loading = false;
                        if (successflag) this.getConversation(task);
                    });
            }
        },
        openBulkUpload(task) {
            this.sendforreviewdrawer = true;
            this.bulkworkpaper = false;
            this.fileremark = '';
            this.resetFlag = !this.resetFlag;
            this.file= [];
            this.fileuplaoded = []
            this.OpenBulkUploadBox(task)
            this.viewtaskdata = { ...task }
        },
        clearfile(){
            this.resetFlag = !this.resetFlag;
            this.file= [];
            this.fileuplaoded = [];
            this.uploaddata=[],
            this.showdatawitherror=false,
            this.dataerror=[],
            this.flag=false,
            this.file=null;
        },

        FilesUpload(filesss) {
            console.log(filesss,"SingleFile");
            console.log(filesss,"bulksingle");
            // this.file= filesss;
            this.file = filesss.filter(file => file.name.startsWith("reco_"));
            this.readFilesinglebulk('file')
    //         this.file = newFilesBulk[0]; 
    //   console.log("edrfgfewefgb",newFilesBulk);
           
    //   this.readFile('file')
      
            if(this.bulkuploadtemplate){
                this.file= filesss[0]
                this.readFile('file')
                console.log(filesss,"TasklistBuk");
            }
            else
            {
            this.fileuplaoded = filesss
            }
        },


        async uploadFiles(id) {
            // let totalSize = 0;
            let arrayPush = [];
            let files = this.fileuplaoded;
            this.sendforreviewloading = true
            // Check if files is an array and has elements
            if (Array.isArray(files) && files.length > 0) {
                // files.forEach((file) => {
                //     if (file.name && (this.fileprocessing[id] || 0) === 0) {
                //         totalSize += file.size;
                //     }
                // });

                // Validate total file size (50 MB limit)
                // if (totalSize > 50 * 1024 * 1024) {
                //     this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
                //     this.files[id] = null;
                //     return;
                // }

                // Process each file in the array
                const uploadPromises = files.map(async (file) => {
                        try {
                            // Upload file using Nova (assuming it returns a promise)
                            let dt = await this.$nova.uploadFile(this.axios, file, {
                                // progressfunction: (e) => {
                                //     let p = (e.loaded * 100) / e.total;
                                //     // this.fileprocessingpercent[id] = p.toFixed(0);
                                //     this.uploadcounter = this.uploadcounter + 0.01;
                                // },
                            });

                            // Store the entire response object
                            arrayPush.push(dt);
                        } catch (err) {
                            // Handle errors during file upload
                            this.fileprocessing[id] = 0;
                            this.$store.commit("sbError", err.message || err || "Unknown error!");
                            console.error(err);
                        }
                    
                });

                // Wait for all upload promises to resolve
                await Promise.all(uploadPromises);

                // Check if any response has a status of "success"
                if (arrayPush.some((response) => response.data.status === "success")) {
                    this.dataArray = arrayPush.flatMap((response) => response.data.data.map((item) => {
                        return { "filename": item.uploadname, "file_id": item._id , "file_path":item.path}
                    }));
                    // this.fileprocessing[id] = 2;

                    // Rest of your code...
                    let data = {
                        upload: this.dataArray,
                        project: this.id,
                        checklist: this.viewtaskdata,
                        bulkupload: false,
                    };

                    // Continue with the post request
                    try {
                        let response = await this.axios.post("/v2/financialclose/analysis/storeworkpaper", {
                            data: [data]
                        });

                        if (response.data.status === "success" && response.data.data.length > 0) {
                            this.$store.commit("sbSuccess", "File uploaded");
                            this.refreshData();
                            // this.fileprocessing[id] = 0;
                            // this.sendforreviewdata.stage = 1;
                            // this.checklist[this.sendforreviewdata.__key] = {
                            //     ...this.sendforreviewdata
                            // };
                            this.sendforreviewdrawer = false;
                        } else {
                            throw new Error(response?.data?.message || "Error uploading file");
                        }
                    } catch (axiosError) {
                        // this.fileprocessing[id] = 0;
                        this.$store.commit("sbError", axiosError.message || axiosError || "Unknown error!");
                        console.error(axiosError);
                    } finally {
                        // Reset file and loading states
                        this.files = {};
                        // this.refreshData();
                        this.sendforreviewloading = false;
                    }
                } else {
                    // this.fileprocessing[id] = 0;
                    this.$store.commit("sbError", "Unable to upload file");
                    // this.sendforreviewloading = false
                }
            } else {
                this.sendforreviewloading = false
                this.$store.commit("sbError", "No files to upload");
                this.sendforreviewloading = false
            }
        },

        async uploadFilesBulk() {
            let files= this.fileuplaoded;
            // let taskIds=  this.selectItem.length>0 ? this.selectItem : [this.viewtaskdata];            
        this.log = 0;
        try {
            let totalSize = 0;
            let dataArrayList = [];
            if (Array.isArray(files) && files.length > 0) {
            files.forEach((file) => {
                if (file.name) {
                totalSize += file.size;
                }
            });
            if (totalSize > 50 * 1024 * 1024) {
                this.$store.commit("sbError", "Total file size exceeds the limit (50 MB)");
                return;
            }
            this.sendforreviewloadingbulk = true;
            const uploadPromises = files.map(async (file) => {
                if (file.name) {
                try {
                    let response = await this.$nova.uploadFile(this.axios, file, {});
                    dataArrayList.push(response);
                } catch (uploadError) {
                    this.$store.commit("sbError", uploadError.message || "Error uploading file");
                    console.error(uploadError);
                }
                }
            });
            this.bulkfilesloading = 0;
            await Promise.all(uploadPromises);
            if (dataArrayList.some((response) => response.data.status === "success")) {
                this.dataArray = dataArrayList.flatMap((item) => item.data.data.flatMap((item) => {
                return { "filename": item.uploadname, "file_id": item._id ,"file_path":item.path}
                }));
                let data = {
                    bulkupload: true,
                    upload: this.dataArray,
                    project: this.id,
                    checklist:this.selectItem.length >0 ? this.selectItem :[ this.viewtaskdata],
                    removelocalfile: false,
                };
                try {
                    this.bulkfilesloading = 0;
                    let response = await this.axios.post("/v2/financialclose/analysis/bulkStoreWorkPaper", { data: [data] });
                    if (response.data.status === "success" && response.data.data.length > 0) {
                    this.$store.commit("sbSuccess", "File uploaded");
                    this.bulkworkpaperupload = false;
                    this.bulkfilesloading = 1;
                    // if (index === (this.selectItem.length - 1)) {
                    // }
                    this.sendForReviewDialogbulk = false;
                    this.sendforreviewdrawer=false
                    this.refreshData();
                    } else {
                    throw new Error(response?.data?.message || "Error Uploading File")
                    }
                } catch (axiosError) {
                    this.filesbulk = {};
                    this.clearuploadfiledata=true
                    this.$store.commit("sbError", axiosError.message || "Unknown error!");
                }
                
                this.sendforreviewloadingbulk = false;
            } else {
                this.$store.commit("sbError", "Unable to upload file ");
                this.sendforreviewloadingbulk = false;
            }
            } else {
            this.$store.commit("sbError", "No files to upload");
            this.sendforreviewloadingbulk = false;
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.filesbulk = {};
            this.bulkfilesloading = 1;
            this.clearuploadfiledata=true
            this.sendforreviewdialogbulk=false;
            this.refreshData();
        }
         this.resetFlag = !this.resetFlag;
        },


        togglePreparer(id) {
            if (!('preparer' in this.addeditdata)) {
                this.$set(this.addeditdata, 'preparer', []);
            }
            const index = this.addeditdata.preparer.indexOf(id);
            if (index === -1) {
                this.addeditdata.preparer.push(id);
            } else {
                this.addeditdata.preparer.splice(index, 1);
            }
        },

        toggleApprover(id) {

            if (!('approver' in this.addeditdata)) {
                this.$set(this.addeditdata, 'approver', []);
            }
            const index = this.addeditdata.approver.indexOf(id);
            if (index === -1) {
                this.addeditdata.approver.push(id);
            } else {
                this.addeditdata.approver.splice(index, 1);
            }
        },
        bulkopenlinkBotDialog: function(d){
            this.addeditdata = {...d};
            this.selectedbot = this.addeditdata.botid;
            this.bulklinkbotdialog = true;
            this.leaseScheduleValue = [];
        },


        addSelectedGls: function () {
            this.selectedGlCodes = []
            console.log("wsertyu",this.selectgls,this.selectedGlCodes);
            
            for (const i of this.selectgls || []) {
                this.selectedGlCodes.push(this.filteredgllist.filter(item => item._id === i)[0]);
            }
            this.addeditdata.__tbtotals={
                total:this.selectedGlCodes.length,
                items: this.selectedGlCodes
            },
            this.addeditdata.__tblinks=this.selectgls
          },


        gettaskdetails(id){
           return this.tasklistData.find((item) => item._id === id)
        },
        bulkActionUserdilog(type){
            this.isLocation = false
            this.selectaction = '';
            
            this.bulkActionUser(type);
        },
        bulkActionUser(type) {
            let selecteditems = this.selectItem;
            let existingusers = new Set();
            let associatedusers = [...(this.metadata.managerlist || []), ...(this.metadata.userlist || [])];
            let possibleids = [];
            if (type) {
                this.bulkactionuserselected = false;
                if (type === 'preparer') this.bulkactionuserusertype = "Preparer";
                else if (type === 'approver') this.bulkactionuserusertype = "Approver";
            }
            for (const i of this.checklist) {
                if (selecteditems.indexOf(i._id) > -1) {
                if (this.bulkactionuserusertype === 'Preparer') (i.preparer || []).forEach(existingusers.add, existingusers);
                else if (this.bulkactionuserusertype === 'Approver') (i.approver || []).forEach(existingusers.add, existingusers);
                }
            }
            if (!this.bulkactionuserselected) {
                for (const i of (this.usersList || [])) {
                    if (associatedusers.indexOf(i._id) > -1) this.possibleusers.push(i);
                }
            } else {
                possibleids = Array.from(existingusers);
                // console.log(possibleids);
                this.possibleusers = [];
                for (const i of (this.usersList || [])) {
                // console.log(i);
                if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
                }
            }
            this.bulkactionuserdialog = true;
        },
        actionselection(select,type){
            if (select === 'remove') {
                this.bulkactionuserselected = true;
                this.bulkActionUser(false);
            } else{
                this.bulkActionUser(type);
            }
        },
        bulkManageUser(action, usertype, userid, selectitems, changeuserid) {            
            let ndt = {};
            if(usertype==="Location"){
                if(userid===""){ 
                throw new Error("Please select location");
                }
            }
            if (action === 'replace') {
                ndt = { fromuserid: userid, touserid: changeuserid, type: action };
            } else {
                ndt = { userid: userid, usertype: (usertype || "").toLowerCase(), type: action };
            }
            let fdt = { type: "checklist", _id: selectitems.map(item => item._id) };

            console.log(ndt,fdt);
            let successflag = false;
            this.axios.post("/v2/financialclose/analysis/bulkmanageuser/" + this.metadata._id, { data: [ndt], filter: fdt })
                .then(dt => {
                if (dt.data.status === "success") {
                    this.bulkactionuserdialog = false;
                    let successMessage = "";
                    if (action === "associate") successMessage = "User Added";
                    else if (action === "remove") successMessage = "User Removed";
                    else if (action === "move") successMessage = "Change to " + usertype;
                    else if (action === "replace") successMessage = "User Replaced";
                    this.$store.commit("sbSuccess", successMessage);
                    this.changetouser = "";
                    successflag = true;
                    this.selectItem = [];
                } else {
                    throw new Error(dt.data.message || "Error changing status");
                }
                })
            .catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            })
            .finally(() => {
            this.bulkactionuserloading = false;
            if (successflag) 
                this.refreshData();
            });
        },
        downloadbbulk(item){
            // $nova.downloadFiles(
            console.log(item);
             let uploadeis = item
                .map(item => item.upload)
                .filter(upload => upload !== undefined);
            this.$nova.downloadFiles(uploadeis);
        },
        changeChecklistStatus(analysis, type) {
            this.loading = true;
            let idtxt = analysis;
            let data = {type: type};
            if(analysis instanceof Array) {
                idtxt = "bulk";
                const tasklistids = analysis.map(item => item._id);
                data.checklist = tasklistids;
                console.log(tasklistids,"analysisanalysisanalysis");
            }
            this.axios.post("/v2/financialclose/analysis/changecheckliststage/"+this.metadata._id+"/a/"+idtxt, {data: [data]}).then(dt => {
                if(dt.data.status === "success"){
                this.$store.commit("sbSuccess", "Status Updated");
                this.refreshData();
                }else throw dt.data.message || "Error changing status";
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        },
        openHelpdeskDialog(d) {
            this.helpdeskitem = { ...d };
            this.helpdeskdialog = true;
            this.helpdeskitemnew = false;
            let url = "/v2/financialclose/analysis/getexistingtickets/" + this.metadata._id + "/c/" + this.helpdeskitem._id;
            this.axios.post(url).then(dt => {
                if (dt.data.status === "success") {
                this.helpdeskitem.__existingtickets = dt.data.data || [];
                if ((this.helpdeskitem.__existingtickets || []).length === 0) this.helpdeskitemnew = true;
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
            })
            console.log("qawsertyui",this.helpdeskitem);
            
        },
        addHelpdeskQuery() {
            let ndt = { issue: this.helpdeskitem.description, description: this.helpdeskitem.__ticketdescripton, modulename: this.$route.meta.modulename };
            let url = "/v2/financialclose/analysis/addfunctionalquery/" + this.metadata._id + "/c/" + this.helpdeskitem._id;
            this.axios.post(url, { data: ndt }).then(dt => {
                if (dt.data.status === "success" && dt.data.data.length > 0) {
                this.$store.commit("sbSuccess", "Ticket raised");
                this.helpdeskdialog = false;
                this.$nova.gotoLink({ path: "/financialclose/functionalsupport/" + this.metadata._id + "/support/" + dt.data.data[0]._id });
                } else throw Error(dt.data.message || "Unknown error")
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
            })
        },
        openlinkBotDialog: function(d){
            this.addeditdata = {...d};
            this.selectedbot = this.addeditdata.botid;
            this.linkbotdialog = true;
            this.leaseScheduleValue = [];
        },
        toggleSelectAllGl(isSelected) {
            if (isSelected) {
                this.selectgls = this.filteredgllist.map(item => item._id);
            } else {
                this.selectgls = [];
            }
        },
        sendForReview() {
        // this.sendforreviewloading = true;
        let url = "/v2/financialclose/analysis/sendforreview/" + this.id + "/c/" + this.viewtaskdata._id;
        this.axios.post(url, { data: { remark: this.fileremark } }).then(dt => {
            if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Tasklist sent for review");
            // this.sendforreviewdialog = false;
            this.files=[];
            this.filesbulk=[];
            this.viewtaskdata.stage = 2;
            this.checklist[this.viewtaskdata.__key] = { ...this.viewtaskdata };
            this.calculateDetails();
            this.refreshData();
            } else throw Error(dt.data.message || "Unknown error")
        }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
        }).finally(() => {
            this.sendforreviewdrawer = false;
        })
        },
        sendForReviewBulk(){
            console.log(this.selectItem);
            const tasklistid = this.selectItem.map(item => item._id);
             let url = "v2/financialclose/analysis/bulksendforreview";
             this.axios.post(url, { data: { remark: this.fileremark ,pid:this.id,cidList:tasklistid} }).then(dt => {
            if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Tasklist sent for review");
            this.bulkworkpaperupload = false;
            // this.sendforreviewdialog = false;
            this.files=[];
            this.filesbulk=[];
            this.selectItem = [];
            this.viewtaskdata.stage = 2;
            this.checklist[this.viewtaskdata.__key] = { ...this.viewtaskdata };
            // this.calculateDetails();
            this.refreshData();
            } else throw Error(dt.data.message || "Unknown error")
        }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
        }).finally(() => {
            this.sendforreviewdrawer = false;
        })
             
        },
    //     filterPart(item,header){
    //     console.log(item,header);
    //     console.log(this.tasklistData,"tasklistData");
        
    //     this.tasklistData = this.$nova.filterByDynamicCriteria(this.tasklistDataCopy, item);  
    //   },
    // clearPart(item){
    //     console.log(item,"item");
    //     this.tasklistData = this.tasklistDataCopy;
    //   }

   sortFun(array, key, order = 'ascending') {
         return array.slice().sort((a, b) => {
             const valA = a[key];
             const valB = b[key];
             const normalizedA = typeof valA === 'string' ? valA.toLowerCase() : valA;
             const normalizedB = typeof valB === 'string' ? valB.toLowerCase() : valB;

             if (normalizedA < normalizedB) return order === 'ascending' ? -1 : 1;
             if (normalizedA > normalizedB) return order === 'ascending' ? 1 : -1;
             return 0;
         });
     },
     sortingOrder(key, order) {
         this.tasklistfilter = this.sortFun(this.tasklistfilterCopy, key, order);
     },
     filterPart(item) {
         this.tableFilterHeader = item;
        //  console.log(item);
        //  console.log(this.tasklistfilter, "tasklistData");
         this.tasklistfilter = this.$nova.filterByDynamicCriteria(this.tasklistfilterCopy, this.tableFilterHeader);
     },
     getRefresh(){
        this.getData();
     },
     async clearPart(item) {
        // this.loading = true;
             try {
                 const payload = {
                     page: this.page_name,
                     key:this.id,
                 };
                 const url = '/v2/financialclose/analysis/removecustomlayout';
                 const response = await this.axios.post(url, {
                     data: payload
                 });
                 if (response.data.status === "success") {
                    //  this.getData();
                     console.log(item, "item");
                    this.tasklistfilter = this.tasklistfilterCopy;
                     this.$store.commit("sbSuccess", "clear Filter");
                     this.loading = false;
                 } else {
                     throw new Error(response.data.message || "Unknown error");
                 }
             } catch (err) {
                 this.$store.commit("sbError", err.message || err || "Unknown error!");
                 console.error("Error clear filter:", err);
             } finally {
                 this.loading = false;
                 console.log("Filter clear request completed.");
             }
        
        
     },
     async saveHeader(headers){
        this.loading = true;
             try {
                 const payload = {
                    custom_layout: headers,
                    page: this.page_name,
                 };
                 const url = '/v2/financialclose/analysis/addcustomlayout';
                 const response = await this.axios.post(url, {
                     data: payload
                 });
                 if (response.data.status === "success" && response.data.data.length > 0) {
                     this.$store.commit("sbSuccess", "Saved Filter");
                     this.menuOpen = false;
                 } else {
                     throw new Error(response.data.message || "Unknown error");
                 }
             } catch (err) {
                 this.$store.commit("sbError", err.message || err || "Unknown error!");
                 console.error("Error saving filter:", err);
             } finally {
                this.menuOpen = false;
                this.loading = false;
                 console.log("Header save request completed.");
             }
     },
     async saveFilter(filterheader) {
        console.log(filterheader);
        
        this.loading = true;
             try {
                 const payload = {
                    filter: { [this.id]: filterheader },
                    page: this.page_name,
                 };
                 const url = '/v2/financialclose/analysis/addcustomlayout';
                 const response = await this.axios.post(url, {
                     data: payload
                 });
                 if (response.data.status === "success" && response.data.data.length > 0) {
                     this.$store.commit("sbSuccess", "Saved Filter");
                 } else {
                     throw new Error(response.data.message || "Unknown error");
                 }
             } catch (err) {
                 this.$store.commit("sbError", err.message || err || "Unknown error!");
                 console.error("Error saving filter:", err);
             } finally {
                this.loading = false;
                 console.log("Filter save request completed.");
             }
         },
        //  async clearFilter() {
        //      this.loading = true;
        //      try {
        //          const payload = {
        //              page: this.page_name,
        //          };
        //          const url = '/v2/financialclose/analysis/removecustomlayout';
        //          const response = await this.axios.post(url, {
        //              data: payload
        //          });
        //          if (response.data.status === "success") {
        //              this.getData();
        //              this.$store.commit("sbSuccess", "clear Filter");
        //              this.loading = false;
        //          } else {
        //              throw new Error(response.data.message || "Unknown error");
        //          }
        //      } catch (err) {
        //          this.$store.commit("sbError", err.message || err || "Unknown error!");
        //          console.error("Error clear filter:", err);
        //      } finally {
        //          this.loading = false;
        //          console.log("Filter clear request completed.");
        //      }
        //      // this.tasklistfilter = this.tasklistfilterCopy;
        //      // this.tableFilterHeader = {};
        //      // console.log("clearfilter");
        //  },
    },
    computed: {
        filteredData() {
            if (this.showdatawitherror) {
            return this.uploaddata.filter((_, rowIndex) =>
                Object.values(this.dataerror[rowIndex]).some((error) => error)
                );
            }
            return this.uploaddata; // Return all rows if not filtering errors
        },
        selectAllgl: {
            get() {
                return this.filteredgllist.length > 0 && this.filteredgllist.every(item => this.selectgls.includes(item._id));
            },
            set(value) {
                this.toggleSelectAllGl(value);
            }
        },
        filteredColumns() {
            if (!this.search?.trim()) {
                return this.configureColumns;
            }
            const searchTerm = this.search.trim().toLowerCase();
            return this.configureColumns.filter(item =>
                item.toLowerCase().includes(searchTerm)
            );
        },
        // filteredHeaders() {
        //     const searchTerm = this.searchcolumn.toLowerCase();
        //     return this.tableHeaders.filter(header =>
        //         header.text.toLowerCase()?.includes(searchTerm)
        //     );
        // },
        filteredHeaders() {
            const searchTerm = this.searchcolumn.toLowerCase();
            return this.tableHeadersfilter.filter(header =>
                header.text.toLowerCase()?.includes(searchTerm)
            );
        },
        // filteredTasklist() {
        //     let array = [];
        //     if (this.userType === 'preparer') {
        //         array = this.tasklistData.filter(item => item.preparer.includes(this.userid));
        //         if (this.toDoTaskOnly) {
        //             return array.filter(item => item.stage === 0);
        //         }

        //         return [...array].sort((a, b) => a.stage - b.stage);
        //     }
        //     else if (this.userType === 'approver') {
        //         array = this.tasklistData.filter(item => item.approver.includes(this.userid));
        //         if (this.toDoTaskOnly) {
        //             return array.filter(item => item.stage === 2);
        //         }
        //         return this.customStageOrder([...array]);
        //     }
        //     else {
        //         return [...this.tasklistData].sort((a, b) => a.stage - b.stage);
        //     }
        // },
        filteredTasklist() {
            console.log(this.userType,"userType");
            
            let array = [];
            if (this.userType === 'preparer') {
                array = this.tasklistfilter.filter(item => item.preparer.includes(this.userid));
                if (this.overDueDate) {
                    console.log(this.overDueDate,"overDueDate");
                    
                    return array.filter(item => item?.duedays < 0);
                }
                return [...array];
                // return [...array].sort((a, b) => a.stage - b.stage);
            }
            else if (this.userType === 'approver') {
                array = this.tasklistfilter.filter(item => item.approver.includes(this.userid));
                if (this.overDueDate) {
                    console.log(this.overDueDate,"overDueDate");
                    return array.filter(item => item?.duedays < 0);
                }
                return [...array];
                // return this.customStageOrder([...array]);
            }
            else if(this.userType === 'manager'){
                array = this.tasklistfilter
                if (this.overDueDate) {
                    console.log(this.overDueDate,"overDueDate");
                    return array.filter(item => item?.duedays < 0);
                }
                return [...array];
                // return this.customStageOrder([...array]);
            }
            else {
                return [...this.tasklistfilter];
                // return [...this.tasklistfilter].sort((a, b) => a.stage - b.stage);
            }
        },

        // filterByTab() {
        //     if (this.selectedTabStage !== null) {
        //         return this.filteredTasklist.filter((item) => item.stage === this.selectedTabStage)
        //     } else
            
        //     return this.filteredTasklist;
        // },
        filterByTab() {
            if (this.selectedTabStage !== null) {
                return this.filteredTasklist.filter((item) => item.stage === this.selectedTabStage)
            } else
            
            return this.filteredTasklist;
        },
        
        filterbysearch() {
            const searchTerm = this.searchQuery.trim().toLowerCase();
            return this.filterByTab.filter(item =>
                item.description.toLowerCase().includes(searchTerm)
            );
        },

        pagecount() {
            return Math.ceil(this.filterbysearch?.length / this.itemsPerPage);
        },
        paginatedFilteredItems() {
            const start = (this.page - 1) * this.itemsPerPage;
            const end = this.page * this.itemsPerPage;
            return this.filterbysearch?.slice(start, end);
        },

        filteredgllist() {
        if (this.searchcustomfilter === "" || this.searchcustomfilter === undefined) {
            return this.tb.filter(item => {
            if (Object.keys(item.location_detail[0] || {}).includes('_id')) {
                if (item.location_detail[0]._id === this.addeditdata.location) {
                return item;
                }
            }
            }
            );
        } else {
            return this.tb.filter(item => {
            if (Object.keys(item.location_detail[0] || {}).includes('_id')) {
                if (item.location_detail[0]._id === this.addeditdata.location) {
                return Object.values(item).some(val =>
                    val !== null && val !== undefined &&
                    val.toString().toLowerCase().includes(this.searchcustomfilter.toLowerCase())
                )
                }
            }
            }
            );
        }
        },
    },
    watch:{
        showdatawitherror(newVal) {
            if (newVal) {
            this.errorsonly = this.dataerror.filter(item => Object.keys(item).length > 0);
            } else {
            this.errorsonly = this.dataerror;
            }
        }
    }


}
</script>

<style scoped>
.tab-header-height {
    height: 7.5vh;
}

.width-tab {
    width: 25%;
    align-items: center;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.padding-tab {
    padding: 16px;
    border-radius: 8px;
}

.gap-tab {
    gap: 20px;
}


.total_task_tab {
    background-color: #F7F7F7;
    color: #828285;
}

.total_task-border {
    border: 1px solid #B2B2B4;
}

.pending_sub_tab {
    background-color: #FAF7F2;
    color: #C69908;

}

.pending-task-border {
    border: 1px solid #C69908;
}

.pending_app_tab {
    background-color: #F3F6FE;
    color: #4285F4;

}

.approve-task-border {
    border: 1px solid #4285F4;
}

.closed_tab {
    background-color: #F3F8F5;
    color: #37AE78;
}

.closed-task-border {
    border: 1px solid #37AE78;
}

.tb_tab_border {
    border: 1px solid #E5E5E5
}

.error-tab {
    background-color: #FDF4F4;
    border: 1px solid #FBEAEA;
    color: #E85B61;

}
.custom-text {
  font-size: 10px !important;
}
</style>